import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import HFTab from '../../../../Components/HfTabs/HfTabs'
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../../assets/mark.png'
import ImageText from '../../../../Components/ImageText/ImageText'

import Stage from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/Stage.jpg'
import HFGuarantees from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/HFGuarantees.jpg'
import HFCarriesOut from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/HFCarriesOut.jpg'
import HFSimplifiesTask from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/HFSimplifiesTask.jpg'
import CorrectStorage from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/CorrectStorage.jpg'
import Coding from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/Coding.jpg'
import RiskAnalysis from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/RiskAnalysis.jpg'
import Disposal from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/Disposal.jpg'
import Testing from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/Testing.jpg'
import HoseLine from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/HoseLine.jpg'
import RapidHelp from '../../../../assets/Services/PreventativeMaintainance/DGUVInspection/RapidHelp.jpg'

const DguvInspection = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: HFGuarantees,
        title: 'HANSA‑FLEX guarantees safety in accordance with the regulations',
        description: 'Hose lines are subject to high loads such as dynamic pressure changes and peaks during their everyday operation. Therefore, hoses and connections must be regularly inspected to see that they are functioning correctly. The safety of personnel, systems and machines depends on hose lines being free of faults and defects. The experts at HANSA‑FLEX are familiar with all the applicable legislation and guidelines, so that all inspection complies with the legal requirements.',
    }

    const data2 = {
        image: HFCarriesOut,
        title: 'HANSA‑FLEX carries out all the necessary inspections.',
        description: "Hydraulic hose line inspections must be performed at regular intervals in accordance with the applicable operational risk assessment, but at least every 12 months. The inspections may be carried out only by officially authorised persons for inspections. The HANSA‑FLEX experts have this qualification and can support you with the following tasks:",
        listItems: [
            "Visual inspections and function tests",
            "Inspection taking into account changes requiring re-inspection",
            "Recurring inspections at the defined intervals"
        ]
    }

    const data3 = {
        image: HFSimplifiesTask,
        title: 'HANSA‑FLEX simplifies the task of documentation',
        description: 'Every inspection report must be recorded and kept safely until the next inspection. Depending on the number of hydraulic hose lines to be inspected, the obligation to prepare and keep inspection records can involve a lot of work for a company. As your service partner, HANSA‑FLEX takes over the recording and management of inspection results. Using the digital hose management platform X-CODE Manager, the machine operator and service technician can call up the latest information about every inspection for every system, machine and hose line.',
    }

    const data4 = {
        image: CorrectStorage,
        description: "Our experts recommend:",
        listItems: [
            "Cool, dry, dust-free, out of direct sunlight",
            "Elastomers not below -10 °C",
            "Ideally between 15 and 25 °C at 65 % relative humidity",
            "Store horizontally and under no strain",
            "Maximum storage time for hose lines is 2 years",
            "Maximum storage time for hose material is 4 years"
        ]

    }

    const featuresData = [
        {
            title: "Improved operational safety",
            description: "Protection for users, systems, machines, and your company’s reputation.",
            image: imgMark
        },
        {
            title: "Reliable legal compliance",
            description: "Comply with the legally prescribed intervals and retain legally compliant documentation.",
            image: imgMark
        },
        {
            title: "Higher availability",
            description: "Avoid unplanned standstills and reduce component wear.",
            image: imgMark
        },
        // {
        //     title: "More transparency",
        //     description: "Have all your hose data and servicing dates ready to hand with the X-CODE Manager hose management platform.",
        //     image: imgMark
        // }
    ];


    //HfTab data
    const imageTextData = [
        // {
        //     description: "For complete tracking of inspection intervals and results, it is advantageous for every hose line to have an individual code. X-CODE from HANSA‑FLEX is a practice-proven solution for coding when a new hose line is included and for follow-up coding of existing hose lines.",
        //     buttonName: "FIND OUT MORE",
        //     buttonLink: "/x-code-hose-identification-system",
        //     image: Coding
        // },
        {
            description: "HANSA‑FLEX has decades of experience to call upon when advising customers on the principles of preparing a risk assessment report. This advice naturally includes the risk analysis in accordance with the Occupational health and safety act 85 of 1993.",
            image: RiskAnalysis
        },
        {
            description: 'As operating equipment contaminated by oil, hydraulic hoses pose a considerable risk to the environment. Transport and recycling are therefore subject to strict legal requirements. Working with a certified waste management company, HANSA‑FLEX takes care of their professional disposal, including storage in tested collection containers and complete documentation.',
            image: Disposal
        },
    ];

    const tabTitles = [
        // { title: "Coding and follow-up coding", eventKey: "Coding and follow-up coding" },
        { title: "Risk analysis and classification", eventKey: "Risk analysis and classification" },
        { title: "Disposal of hydraulic hose lines", eventKey: "Disposal of hydraulic hose lines" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"INSPECTION OF HYDRAULIC HOSE LINES"}
                    paragraph={"Hydraulic hose lines are fundamental components of machines and systems. They require comprehensive care and must be professionally inspected by authorised persons every 12 months after being brought into first use. HANSA-FLEX takes care of the scheduling, performs the inspections and prepares the documentary record of the legally prescribed visual inspection and function test. This take some of the load off you the operator and increases the safety of your work equipment."}
                />
            </div>
            <div>
                <SectionHeader
                    title={"INSPECTION BY HYDRAULIC HOSE LINE EXPERTS"}
                    subtitle={"As a fluid technology system provider, HANSA‑FLEX knows what has to be considered when inspecting hydraulic hose lines and offers a comprehensive service for everything to do with hose management performed by experienced and trained specialist personnel."}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                {/* <ImageText
                    data={data3} /> */}
            </div>

            {/* <div>
                <SectionHeader
                    title={"WHAT MUST BE CONSIDERED WHEN INSPECTING HYDRAULIC HOSE LINES"}
                    subtitle={"The most important information for inspecting hydraulic hose lines and maintaining hydraulic systems and machines is explained clearly and concisely in our brochure “Hose management – Operating hydraulic equipment safely and legally”"}
                />
            </div> */}

            <div>
                <SectionHeader
                    title={"INSPECTION BY HANSA‑FLEX OFFERS SAFETY WITH ADDED VALUE"}
                    subtitle={"You can rely on a high level of expertise and comprehensive process knowledge for inspections with HANSA‑FLEX as your service partner."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Carousel */}

            <div>
                <SectionHeader
                    title={"CORRECT STORAGE OF HYDRAULIC HOSE LINES"}
                    subtitle={"In addition to inspection, HANSA‑FLEX provides comprehensive advice on the optimum storage of hydraulic hoses and hose lines in accordance with DIN 20066:2021-01."}
                />
                <ImageText
                    data={data4} />
            </div>

            <div>
                <SectionHeader
                    title={"LEGALLY COMPLIANT DOCUMENTATION BASED ON INSPECTION RECORDS"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            {/* TODO:CLient Review */}

            <div>
                <SectionHeader
                    title={"MORE SERVICES FOR YOUR HYDRAULIC SYSTEMS"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Testing} learnMoreLink={"/oil-analysis"}
                                title="Testing of hydraulic fluids"
                                desc={"HANSA‑FLEX Fluid Service analyses hydraulic oil for optimum fluid care. In this way problems are detected in good time and expensive standstills prevented."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={HoseLine} learnMoreLink={"/x-code-manager"}
                                title="Hose line management with X-CODE"
                                desc={"The digital hose management system from HANSA‑FLEX contains all your hydraulic hose lines so that inspections can be completed more quickly and efficiently."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={RapidHelp} learnMoreLink={"/mobile-rapid-hydraulic-service"}
                                title="Rapid help in an emergency"
                                desc={"Unplanned standstills must be dealt with as quickly as possible. The HANSA‑FLEX emergency service stands ready to do this all round the clock, 365 days a year."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default DguvInspection 