import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgHfPartner from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/HfPartner.jpg'
import imgHoseLines from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/hoseLines.jpg'
import imgHFcan from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/HFcan.jpg'
import imgStrict from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/strictTesting.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import imgAdviceAndDelivery from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/AdviceAndDelivery.jpg'
import HFTabs from '../../../Components/HfTabs/HfTabs';
import imgFood from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Food.jpg'
import imgChemicals from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Chemicals.jpg'
import imgMedia from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Media.jpg'
import imgWater from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Water.jpg'
import imgOil from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Oil.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgPtfeHose from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/PtfeHose.jpg'
import imgMetalHose from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/MetalHose.jpg'
import imgOperationOfHoseLines from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/OperationOfHoseLines.jpg'




function IndustrialHoses() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const AnExtensive1 = {
        image: imgHoseLines,
        title: 'Readily available hoses',
        description: 'We can process orders for industrial hoses to meet your needs quickly and accurately from our modern central warehouse. Standard products are usually delivered within a maximum of two days after receipt of the order. We can provide the full range of industrial hoses to cover any application, from construction to food processing, along with all accessories, including connection systems.',
        listItems: ["Our warehouse always has over 1400 different items available", "The complete range of hoses and accessories can be provided quickly", "Modern warehouse logistics ensure smooth processing"]
    }

    const AnExtensive2 = {
        image: imgHFcan,
        title: 'HANSA-FLEX always finds a solution',
        description: 'In addition to standard hoses for transporting chemicals, foodstuffs, air, water, and abrasive media, HANSA-FLEX also offers suction and positive pressure hoses in all variants. Items not found in our usual range can be included in our supplied product list and manufactured in our hose specialist center according to customer specifications.',
        listItems: ["Pre-assembled hose lines according to customer needs", "If we are informed of the need for items, we stock up in advance", "Delivery of fully stocked containers for large construction sites"]
    }

    const AnExtensive3 = {
        image: imgStrict,
        title: 'Stringent testing and high-quality criteria',
        description: 'All hoses to which T002 applies (German chemical industry data sheet "Hoses - safe use") are subjected to intensive testing at HANSA-FLEX. These tests include electrical conductivity, pressure testing up to five times the working pressure, and unique hose identification.',
        listItems: ["Manufactured in compliance with the Pressure Equipment Directive, DGUV and T002", "HANSA-FLEX is certified according to DIN EN ISO 9001", "All components from the same supplier for maximum system safety"]
    }

    const AnExtensive4 = {
        image: imgAdviceAndDelivery,
        description: 'When choosing the most suitable hose tubing for your industrial application, HANSA-FLEX experts always consider the broader aspects of your entire installation, as well as the medium, pressure, and temperature.',
        listItems: ["HANSA-FLEX's industrial hose center has over 1,400 items", "Full range of accessories, such as nozzles and fittings", "High-capacity hose specialist workshop", "Stringent quality standards in compliance with DGUV and T002", "Standard components supplied with special speed"]
    }

    //HfTab data
    const imageTextData = [
        {
            title: "Food and potable water hoses for highest cleanliness",
            description: "Hoses used in the food industry must be extremely resistant to abrasion and must ensure the highest cleanliness standards. HANSA-FLEX food hoses meet these requirements and are free from odors, flavors, and toxins, making them suitable according to FDA, BFR, and Japanese MHLW requirements for transporting and processing liquid, solid, and fat foodstuffs.",
            buttonName: "MORE INFORMATION",
            buttonLink: "https://shop.HANSA-FLEX.co.za/en_GB/hoses/industrial-hoses/food-hoses/c/webcat_HF_SCH_1060_3790",
            image: imgFood
        },
        {
            title: "Inert chemical hoses for extremely high loads",
            description: "Acids, alkalis, and saline solutions are highly reactive and corrode hoses. With their multi-layer construction and durable inner layers, HANSA-FLEX chemical hoses are highly resistant to chemicals and solvents, making them ideal for use in the chemical and pharmaceutical industries.",
            listItems: ["Transport of acids, alkalis, paints, varnishes, and alcohols", "Chemical distribution hoses and paint spraying hoses", "Vacuum-resistant variants also available"],
            buttonName: "CHEMICAL HOSES",
            buttonLink: "https://shop.HANSA-FLEX.co.za/en_GB/hoses/industrial-hoses/chemical-hoses/c/webcat_HF_SCH_1060_3760",
            image: imgChemicals
        },
        {
            title: "Robust hoses for abrasive media",
            description: "Granular media such as concrete and sand are particularly abrasive to hoses. Due to their high abrasion resistance and excellent flexibility, HANSA-FLEX industrial hoses excel in the transportation of abrasive and granular media.",
            listItems: ["Suction, delivery, and sandblasting", "Including use with heavy bulk materials", "Weather-resistant construction for outdoor use"],
            buttonName: "ABRASION-RESISTANT HOSES",
            buttonLink: "https://shop.HANSA-FLEX.co.za/en_GB/hoses/industrial-hoses/hoses-for-abrasive-media/c/webcat_HF_SCH_1060_3830",
            image: imgMedia
        },
        {
            title: "Versatile water hoses",
            description: "Water plays an important role in numerous industrial applications, for example, for cooling or cleaning. The HANSA-FLEX product portfolio contains a wide selection of water hoses for various applications.",
            listItems: ["Hoses for sewer cleaning, fire fighting, and construction.", "Industrial, radiator, and cleaning equipment hoses", "Soft PVC hoses"],
            buttonName: "ALL WATER HOSES",
            buttonLink: "https://shop.HANSA-FLEX.co.za/en_GB/hoses/industrial-hoses/water-hoses/c/webcat_HF_SCH_1060_3840",
            image: imgWater
        },
        {
            title: "Oil and fuel-resistant hoses",
            description: "Environmental protection and workplace safety play an important role in the transport of gasoline, diesel, or kerosene, as well as motor, silicone, and unrefined oils. HANSA-FLEX's special hoses are designed to provide the highest performance and maximum safety and are available in a wide range of elastomeric and plastic materials.",
            listItems: ["Pumps for gas station fueling and tanker hoses with or without helix", "Tested for resistance to oil, gasoline, and diesel fuel."],
            buttonName: "ALL FUEL HOSES",
            buttonLink: "https://shop.HANSA-FLEX.co.za/en_GB/hoses/industrial-hoses/oil-and-fuel-hoses/c/webcat_HF_SCH_1060_3810",
            image: imgOil
        },
    ];

    const tabTitles = [
        { title: "Food", eventKey: "Food" },
        { title: "Chemicals", eventKey: "Chemicals" },
        { title: "Abrasive media", eventKey: "Abrasive media" },
        { title: "Water", eventKey: "Water" },
        { title: "Oils and fuels", eventKey: "Oils and fuels" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHfPartner}
                    title={"HANSA-FLEX IS YOUR SYSTEM PARTNER FOR INDUSTRIAL HOSES"}
                    paragraph={"Industrial hoses find application where the medium is not used to transmit energy. Industrial hoses transport a wide variety of media, from water and air to chemicals, fuels, and gases, in almost every industry sector. In its hose specialist workshop, HANSA-FLEX meets customer requirements with the highest quality standards and shortest delivery times. In the HANSA-FLEX online shop, you will find a wide range of industrial hoses."}
                />
                {/*TODO button*/}
            </div>

            <div>
                <SectionHeader
                    title={"A WIDE RANGE AND HIGH QUALITY FROM A SINGLE SUPPLIER"}
                />
                <div>
                    <ImageText
                        data={AnExtensive1} />
                    <ImageText
                        data={AnExtensive2}
                        left={false} />
                    <ImageText
                        data={AnExtensive3} />
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"HANSA-FLEX MEANS RELIABLE ADVICE AND FAST DELIVERY"}
                    subtitle={"At its industrial hose center, HANSA-FLEX has all the resources to provide expert advice and deliver products quickly."}
                />
                <ImageText data={AnExtensive4} />
            </div>

            <div>
                <SectionHeader
                    title={"SUCTION AND DISTRIBUTION HOSES FOR INDUSTRIAL APPLICATIONS"}
                    subtitle={"HANSA-FLEX provides high-quality hoses for water, air, and gas, as well as hoses for oils, fuels, solids, hot media, chemicals, solvents, and food."}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"HOSES PERFECTLY DESIGNED FOR PNEUMATIC APPLICATIONS"}
                    subtitle={"The HANSA‑FLEX online shop has a large selection of air hoses in a wide range of diameters, lengths and materials such as PA, PE, PUR, PVDF or PVC. The distinct colours of the hoses in combination with many other safety devices ensure maximum safety."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgPtfeHose} learnMoreLink={"/Ptfe-hoses"}
                                title="PTFE hoses with high chemical resistance"
                                desc={"Due to their high chemical resistance to most media, HANSA‑FLEX PTFE hoses are the first choice for all challenging applications."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgMetalHose} learnMoreLink={"/metal-hoses"}
                                title="Metal hoses for all conditions of use"
                                desc={"Tolerance to high temperatures and resistance to aggressive media are behind the special performance capabilities of metal hoses."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgOperationOfHoseLines} learnMoreLink={"/hydraulic-academy"}
                                title="Safe and proper operation of hoses"
                                desc={"Industrial hoses are subject to numerous safety requirements. HANSA‑FLEX provides the essential details of these requirements in the product technical information."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    );
}

export default IndustrialHoses;
