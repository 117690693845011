import React, { useEffect } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgContactusAfricannew from '../../assets/Contact us-Africannew.png';
import imgHeadquarters from "../../assets/ContactLocation/Hansa-Flex-headquarters.png"
import imgSAJohannesburg from "../../assets/ContactLocation/SAJohannesburg.png"
import imgSADurban from "../../assets/ContactLocation/SADurban.png"
import imgSACapetown from "../../assets/ContactLocation/SACapetown.png"
import imgSAVereeniging from "../../assets/ContactLocation/SAVereeniging.png"
import imgSAFieldService from "../../assets/ContactLocation/SAFieldService.png"




const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const units = [
        {
            name: 'HANSA-FLEX South Africa, Headquarters ',
            location: '45 Steel Road, Spartan, Kempton Park, South Africa  ',
            telephone: '+27 11 394 2966',
            // fax: "jhgyuyuii",
            mail: 'hst@hansa-flex.com',
            image: imgHeadquarters
        },
        {
            name: 'HANSA-FLEX South Africa, Johannesburg Branch  ',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: '39 Steel Road, Spartan, Kempton Park, South Africa.',
            telephone: ' +27 11 394 2966',
            mail: 'hst@hansa-flex.com',
            image: imgSAJohannesburg
        },
        {
            name: 'HANSA-FLEX South Africa, Durban Branch   ',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: 'Unit 7, 8 reed place, Maxmead, Pinetown, South Africa.',
            telephone: '+27 31 700 3211',
            mail: 'hsw@hansa-flex.com',
            image: imgSADurban
        },
        {
            name: 'HANSA-FLEX South Africa, Cape Town Branch ',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: 'Unit 9, Rosbur Park, 53 Carlisle Street, Paarden Eiland, Cape Town, South Africa, 7405',
            telephone: '+27 21 511 9670',
            mail: 'hsp@hansa-flex.com',
            image: imgSACapetown
        },
        {
            name: 'HANSA-FLEX South Africa, Vereeniging Branch  ',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: '3 Houtkop Road, Vereeniging, South Africa',
            telephone: '+27 16 428 1284',
            mail: 'hsv@hansa-flex.com',
            image: imgSAVereeniging
        },
        {
            name: 'HANSA-FLEX South Africa, Africa Exports   ',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: '39 Steel Road, Spartan, Kempton Park, South Africa.',
            telephone: '+27 11 394 2966',
            mail: 'ex.za@hansa-flex.com',
            image: imgSAVereeniging
        },
        {
            name: 'HFSA24 Hydraulic Field Service',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: '45 Steel Road, Spartan, Kempton Park, South Africa.',
            telephone: '+27 11 394 2966',
            mail: 'hfsa24@hansa-flex.com',
            image: imgSAFieldService
        },

        // Add more units as needed
    ];

    return (
        <div className="">
            <div>
                <CenterStage
                    imageSrc={imgContactusAfricannew}
                    title={"Get in touch with HANSA-FLEX South Africa: Working together for your hydraulic success "}
                    paragraph={"Dive into a world of hydraulic expertise and personalised service. Contact us today and see how HANSA-FLEX South Africa can take your hydraulic operations to new heights."} />
            </div>


            <div className=''>
                <ContactUsCard units={units} />
            </div>
        </div>
    );
};

export default ContactUs;
