import React from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import imgThumbsup_01 from '../../assets/Approvals/Thumbsup-01.jpg';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import ImageCard from '../../Components/ImageCard/ImageCard';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../assets/mark.png';
import { Col, Container, Row } from 'react-bootstrap';
import imgSAApprovals1 from "../../assets/Approvals/SAApprovals1.png";
import imgSAApprovals2 from "../../assets/Approvals/SAApprovals2.jpg";
import imgSAApprovals3 from "../../assets/Approvals/SAApprovals3.jpg";
const Approvalsnew = () => {


    // features card carousel data
    const featuresData = [
        {
            title: "ABS",
            description: "",
            image: imgMark
        },
        {
            title: "DNV GL",
            description: "",
            image: imgMark
        },
        {
            title: "Dragoco",
            description: "",
            image: imgMark
        },
        {
            title: "Bureau Veritas",
            description: "",
            image: imgMark
        },
        {
            title: "Deutsche Bahn",
            description: "",
            image: imgMark
        },
        {
            title: "Lloyd´s Register",
            description: "",
            image: imgMark
        },
        {
            title: "See.Berufsgenossenschaft",
            description: "",
            image: imgMark
        },
        {
            title: "SLV Zulassungen",
            description: "",
            image: imgMark
        },
        {
            title: "Fachbetrieb nach WHG-Wasserhaushaltsgesetz",
            description: "",
            image: imgMark
        },
        {
            title: "See.Berufsgenossenschaft",
            description: "",
            image: imgMark
        },
        {
            title: "Staatliche Brautechnische Prüf- und Versuchsanstalt",
            description: "",
            image: imgMark
        },
        {
            title: "South African Fluid Power Association (SAFPA)",
            description: "",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];


    return (
        <div>
            <CenterStage
                imageSrc={imgThumbsup_01}
                title={"NATIONAL AND INTERNATIONAL APPROVALS"}
                paragraph={"HANSA‑FLEX has a large number of national and international approvals and supplier qualifications. Authorities, government clients, industry associations and renowned classification societies regularly confirm and document the high quality and suitability of our offerings as a manufacturer, supplier and service provider in the course of various audits and approval procedures."}
            />
            <div>

                <SectionHeader
                    title={"TESTED AND APPROVED"} /><br />

            </div>



            <div>

                <FeatureCardCarousel featuresData={featuresData} />

            </div>
            <div>
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={4}><ImageCard image={imgSAApprovals1} learnMoreLink={"/Certificates"}
                            title="Certified reliability and quality"
                            desc={"HANSA‑FLEX SOUTHAFRICA has ISO-certified management systems for quality management, environmental management and occupational safety."} /></Col>

                        <Col sm={12} md={6} lg={4}><ImageCard image={imgSAApprovals2} learnMoreLink={"/HosesAndHoseLines"}
                            title="Hose lines for every purpose"
                            desc={"HANSA‑FLEX offers high-quality hydraulic hose lines for all types of applications, from individual production to series production for original equipment."} /></Col>

                        <Col sm={12} md={6} lg={4}><ImageCard image={imgSAApprovals3} learnMoreLink={"/CompressionFittings"}
                            title="Precision and variety: Pipelines from HANSA‑FLEX"
                            desc={"HANSA‑FLEX manufactures bent pipelines from various materials as individual items or in series for all common types of connections."} /></Col>
                    </Row>
                </Container>
            </div>








        </div>
    )
}

export default Approvalsnew