import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { FaPhoneVolume } from "react-icons/fa6";
import { MdOutlineEmail, MdOutlineFax } from "react-icons/md";
import { BiLogoFacebookCircle } from "react-icons/bi";
import Imgxing from '../../assets/xingImg.png'
import Imglinkidin from '../../assets/Linkdin.png'
import Imgyoutube from '../../assets/youtube.png'
import Imgkaggle from '../../assets/kaggleImg.png'
import Imgfacebook from '../../assets/Facebook.png'
import { Link } from 'react-router-dom';
import './HfFooter.css'

const HfFooter = () => {
  return (
    <footer className='hfFooter-wrapper' id='contact-us-section'>
      <Container className='hfFooter-container'>
        <Row className="justify-content-center " style={{ marginLeft: 'auto' }}>
          <Col sm={12} lg={4} >
            <div className='footer-column-div'>
              <b>How to reach us</b>
              <ul>
                <li><a href="fax:+19057602224" className="footer-link"><MdOutlineFax /> +27 11 394 5836</a></li>
                <li><a href="tel:+27 11 394 5836" className="footer-link"><FaPhoneVolume /> +27 11 394 5836</a></li>
                <li><a href="mailto:hst@hansa-flex.com" className="footer-link"><MdOutlineEmail /> hst@hansa-flex.com</a></li>


                {/* <li> <span style={{ fontSize: '1.2rem' }}><MdOutlineFax /></span>+27 11 394 5836</li>
                <li><span style={{ fontSize: '1rem' }}><FaPhoneVolume /></span> +27 11 394 5836</li>
                <li> <span style={{ fontSize: '1.2rem' }}><MdOutlineEmail /></span>hst@hansa-flex.com</li> */}

              </ul>
            </div>

          </Col>

          <Col sm={12} lg={4}>
            <div className='footer-column-div'>
              <b>About HANSA‑FLEX</b>
              <ul>
                {/* <li><Link to="/management" className="footer-link">Management</Link></li> */}
                <li><Link to="/certificates" className="footer-link">Certificates</Link></li>
                <li><Link to="/Approvalsnew" className="footer-link">Approvals</Link></li>
                <li><Link to="/about-sa" className="footer-link">Who we are</Link></li>
                {/* <li><Link to="https://www.Hansa-Flex.de/en/company/" className="footer-link"> More About Hansa-Flex Group</Link></li> */}
                <li> <a href="https://www.Hansa-Flex.de/en/company/" target="_blank" rel="noopener noreferrer" className="footer-link"> More About Hansa-Flex Group</a></li>

                {/* <li><Link to="/careers" className="footer-link">Careers</Link></li> */}
              </ul>
            </div>
          </Col>


          {/* <Col xs={4} lg={3}>
          <div className='footer-column-div'>
          <h>Service</h>
          <ul>
                <li>Mobile rapid hydraulic service</li>
                <li>Fluid management</li>
                <li>Assembly and installation</li>
                <li>Hydarulic cylinder repair</li>
                <li>Preventive maintenance</li>
            </ul>
          </div>
          </Col> */}

          <Col sm={12} lg={4}>
            <div className='footer-column-div'>
              {/* <h>Popular catogaries</h><br /> */}
              <b>Products</b>
              <ul>
                <li className='mb-0'><Link to="/hoses-and-hose-lines" className="footer-link">Hoses and Hose lines</Link></li>
                <li className='mt-0'><Link to="/ext-high-pfm-hoses" className="footer-link">Hydraulic steel tube</Link></li>
                {/* <a href="https://my.Hansa-Flex.in/en_GB/login" target="_blank" rel="noopener noreferrer" className="footer-link">X-CODE Manager</a> */}
              </ul>
              <b>Services</b>
              <ul>
                <li className='mb-0'><Link to="/mobile-rapid-hydraulic-service" className="footer-link">The mobile workshop: hydraulic service container</Link></li>
                <li className='mt-0'><Link to="/cylinder" className="footer-link">Hydraulic cylinder repairs</Link></li>
                {/* <a href="https://my.Hansa-Flex.in/en_GB/login" target="_blank" rel="noopener noreferrer" className="footer-link">X-CODE Manager</a> */}
              </ul>


            </div>
          </Col>

        </Row>

        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <b>Follow us</b>
          </Col>
        </Row>


        <Row className="custom-3row ">

          {/*<Col><div className="circle-col"><img src={Imgkaggle} alt="" /></div></Col>
          <Col><div className="circle-col"><img src={Imgxing} alt="" /></div></Col> */}
          <Col><div className="circle-col"> <a href="https://www.linkedin.com/company/42970127/admin/feed/posts/" target="_blank" rel="noopener noreferrer"><div>< img src={Imglinkidin} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://www.facebook.com/profile.php?id=100063896441835" target="_blank" rel="noopener noreferrer"><div><img src={Imgfacebook} alt="" /></div></a></div></Col>
          {/* <Col><div className="circle-col"><a href="https://www.youtube.com/@HANSAFLEXAG" target="_blank" rel="noopener noreferrer"><img src={Imgyoutube} alt="" /></a></div></Col> */}


        </Row>

        <Row className="line-row">
          <Col className="line-col"></Col>
        </Row>

        <div className='HfFooter-link-div'>
          <a className="hf-footer-link" href="">© Copyright All Rights Reserved</a><span>  |  </span>
          <Link to="/Hansa-Flex-india-privacy-policy" className="footer-link">Privacy Policy</Link>
          {/* <Link to="/Hansa-Flex-terms-and-conditions" className="footer-link">Terms and Conditions</Link> */}
          {/*<a className="hf-footer-link" href="">GT&C</a><span>  |  </span>
          <a className="hf-footer-link" href="">Purchasing and logistics</a><span>  |  </span>
        <a className="hf-footer-link" href="">Data protection</a>*/}

        </div>



      </Container>
    </footer >

  );
};

export default HfFooter;
