import React, { useEffect } from 'react';
import ImageDescription from '../../Components/ImageDescription/ImageDescription';
import imgElectra from '../../assets/news-events/electraNews2.jpg'
import imgElectra2 from '../../assets/news-events/electraNews3.jpg'
import imgSAnews1b from "../../assets/news-events/SAnews1b.jpg"
import imgSAnews1c from "../../assets/news-events/SAnews1c.jpg"
import imgElectraMining from "../../assets/news-events/electra_mining2024.jpg"
import imgElectraMining_banner from "../../assets/news-events/electraMining_banner.jpg"


const ElectraMining = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    return (
        <div>
            <ImageDescription
                imageSrc={imgElectraMining}
                title={"ELECTRA MINING 2024"}
                paragraphs={[
                    "Electra Mining 2024 is an exciting event that promises to be a game-changer in the mining industry. The event, scheduled for 2-6 September 2024, is set to showcase innovative hydraulic products and services that have been developed with great dedication and effort. The team behind these advancements in hydraulic technology is eager to share their work with attendees, offering a unique, hands-on experience with their state-of-the-art solutions. These cutting-edge technologies are designed to enhance operational efficiency and take businesses to the next level."
                ]}

                alignment={'center'}
            />

            <ImageDescription
                imageSrc={imgElectraMining_banner}
                paragraphs={[
                    "This premier mining event is more than just a showcase; it's an opportunity for connection, exploration, and growth. Attendees will have the chance to interact directly with the team, gaining insights into the latest developments in hydraulic technology. The event aims to foster a sense of community among industry professionals, encouraging them to learn from each other and grow together. So, mark your calendars for Electra Mining 2024 - a platform for innovation, connection, and growth in the mining industry. See you there!"
                ]}
                alignment={'center'}
            />
        </div>
    );
}

export default ElectraMining