import React, { useEffect } from 'react';
import ImageDescription from '../../Components/ImageDescription/ImageDescription';
import imgtraining from '../../assets/news-events/trainingEvent.jpg'
import imgtraining2 from '../../assets/news-events/trainingEvent2.jpg'
import imgSAnews2a from "../../assets/news-events/SAnews2a.jpg"
import imgSAnews2b from "../../assets/news-events/SAnews2b.jpg"
import TextCaption from '../../Components/TextCaption/TextCaption';

const TrainingNews = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    return (
        <div>
            <ImageDescription
                imageSrc={imgSAnews2a}
                title={"Gaining in-depth knowledge on hydraulic components"}
                paragraphs={["Staff at HANSA-FLEX South Africa Enhance Their Expertise in Hydraulic Components Under Mr. Paleka’s Guidance. ", "In a recent development, the dedicated team at HANSA-FLEX South Africa had the unique opportunity to receive specialized training on hydraulic components from Mr. Paleka, hailing from HANSA-FLEX Croatia. The intensive training sessions spanned several days and left a lasting impact on the team members.,Mr. Paleka, a seasoned expert in hydraulic systems, shared his wealth of knowledge with the staff. From the fundamentals of fluid dynamics to intricate details about pumps, valves, and actuators, the team delved into the world of hydraulics. Participants were engaged in hands-on exercises, simulations, and practical demonstrations, allowing them to grasp complex concepts effectively."

                ]}

                alignment={'center'}
            />




            <TextCaption
                title={"Key Takeaways"}
                bulletPoints={[
                    "Understanding System Design: The team learned how to design efficient hydraulic systems tailored to specific applications. Mr. Paleka emphasized the importance of considering factors such as pressure, flow rate, and load requirements. ",
                    "Troubleshooting Techniques: Armed with new insights, the staff can now troubleshoot hydraulic issues more effectively. Whether it’s identifying leaks, diagnosing pump failures, or optimizing system performance, they are well-equipped to tackle challenges head-on.",
                    "Maintenance Best Practices: Mr. Paleka stressed preventive maintenance as a crucial aspect of hydraulic system longevity. The team now understands the significance of regular inspections, fluid analysis, and component replacement schedules. ",
                    "Safety Measures: Safety was a recurring theme throughout the training. The team learned about safe handling practices, proper use of personal protective equipment, and emergency procedures. "

                ]} />



            <TextCaption
                title={"Enhancing Customer Service"}
                bulletPoints={[
                    "Improved Problem-Solving: When customers encounter hydraulic issues, the team can now diagnose problems swiftly and offer effective solutions. This translates to reduced downtime and increased customer satisfaction. ",
                    "Tailored Recommendations: With a deeper understanding of hydraulic components, staff members can recommend the most suitable products for specific applications. Whether it’s selecting the right pump or suggesting maintenance intervals, their advice will be more informed.",
                    "Confidence in Communication: The team can now engage in meaningful conversations with customers, explaining technical details and addressing queries. Clear communication builds trust and fosters long-term relationships. ",
                    "A Bright Future - As HANSA-FLEX South Africa continues to serve its clients, the impact of Mr. Paleka’s training will reverberate. The team’s enhanced skills promise smoother operations, happier customers, and a more resilient business. "

                ]} />


        </div>
    );
}

export default TrainingNews