import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgIFP from '../../assets/units/IFP/IFP2exterior.jpg';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import '../PageCss/HansaFlexIndiaPages/IFP.css';
import ImageText from '../../Components/ImageText/ImageText';
import imgNipple from '../../assets/units/IFP/Nipple.jpg';
import imgInsert from '../../assets/units/IFP/Insert.jpg';
import imgFerrules from '../../assets/units/IFP/Ferrules.jpg';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../assets/mark.png';
import imgPipeFitting from '../../assets/units/IFP/PipeFitting.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ContactBubble from '../../Components/ContactBubble/ContactBubble';
import imgMonika from '../../assets/management/DSC_6079-1.JPG';
import imgYogesh from '../../assets/management/DSC_6048 copy.jpg';

import { Row, Col } from 'react-bootstrap';
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";


function IFP() {

    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 320, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 300, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        });
    }, []);

    const dataNipple = {
        image: imgNipple,
        title: 'Sealed Precision: HANSA-FLEX Threaded Nipples',
        description: 'HANSA-FLEX threaded weld nipples provide an alternative for connecting standardized hydraulic pipes and threaded connectors. \n These nipples have a sealing cone fitted with an O-ring, precisely fitting inside the mating part of the threaded connector.',

    }

    const dataInserts = {
        image: imgInsert,
        title: 'Precision Perfected: HANSA-FLEX Inserts',
        description: 'Our inserts redefine fluid transport by seamlessly integrating into hose assemblies, offering unparalleled versatility and reliability. Engineered for precision, our inserts ensure optimal performance even in the most challenging environments, where traditional hose connections fall short.',

    }

    const dataFerrules = {
        image: imgFerrules,
        title: ' HANSA-FLEX Ferrules for Seamless Hose Integrations',
        description: ' Crafted to perfection, these ferrules are tailored for standard use in medium- to high-pressure environments, ensuring optimal performance under demanding conditions. With a comprehensive range of connecting threads, including metric, UNF, NPT, and imperial options, alongside SAE flange heads and other flange systems, our ferrules cater to diverse industry requirements with precision and adaptability. ',

    }

    const dataPipeFittings = {
        image: imgPipeFitting,
        title: ' HANSA-FLEX Ferrules for Seamless Hose Integrations',
        description: 'HANSA-FLEX offers a comprehensive range of pipe fittings designed for hydraulic connection equipment Originally developed for high-pressure applications, HANSA-FLEX flare fittings are widely used in locations exposed to strong vibrations. They can be fitted on standard threaded connectors, with the pipe end prepared with a standardized 37° flare cone.',

    }


    // features card carousel data
    const featuresData = [
        {
            title: "Maximum Performance",
            description: "All products are subject to comprehensive testing within the HANSA‑FLEX quality assurance system.",
            image: imgMark
        },
        {
            title: "Comprehensive Service",
            description: "HANSA‑FLEX is the system provider for fluid technology products and services.",
            image: imgMark
        },
        {
            title: "Maximum Safety",
            description: "We offer a comprehensive product range, with everything available online as well.",
            image: imgMark
        },
        {
            title: "Excellent Corrosion Protection",
            description: "We are Certified! We promise safety and quality.",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <CenterStage
                imageSrc={imgIFP}
                title={"IFP Insert & Ferrules Production Unit "}
                paragraph={"Welcome to the Insert & Ferrules Production Unit (IFP), established in 2022. Our state-of-the-art manufacturing facility is dedicated to crafting premium Insert & Ferrules products. Our commitment is built on three key pillars: ensuring Top Quality for longevity in tough conditions, employing Modern Technology with the latest machines for perfection, and maintaining a team always ready to provide support under the principle that We're Here for You, prioritizing customer satisfaction. "}
            />

            <div class="sh-ifp-products">
                <SectionHeader
                    title={"IFP Products"}
                    subtitle={"Explore the excellence of Insert & Ferrules with IFP"}
                />
            </div>


            <>
                <div data-aos={isMobile ? "fade-up" : "fade-right"}>
                    <br />
                    <ImageText data={dataNipple} /> <br /><br />
                </div>
                <div data-aos={isMobile ? "fade-up" : "fade-left"}>
                    <br />
                    <ImageText data={dataInserts} left={false} /><br /><br />
                </div>
                <div data-aos={isMobile ? "fade-up" : "fade-right"}>
                    <br />
                    <ImageText data={dataFerrules} /> <br /><br />
                </div>
                <div data-aos={isMobile ? "fade-up" : "fade-left"}>
                    <br />
                    <ImageText data={dataPipeFittings} left={false} /><br /><br />
                </div>
            </>
            {/*   add more products */}

            <div data-aos={isMobile ? "fade-up" : "zoom-in"} >
                <SectionHeader
                    title={"Why Choose HANSA-FLEX Hose Fittings? "}
                    paragraph={"HANSA-FLEX, a leading supplier of hydraulic solutions, offers a comprehensive range of hose fittings designed for industrial and high-pressure applications."}
                />

                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            {/* <div>
                <SectionHeader title={"Meet our Management"} /> <br /><br /><br />
                <ContactBubble imageSrc={imgMonika} title={"Monika Gholap"} subTitle={"Indian Resident director"} />
                <ContactBubble imageSrc={imgYogesh} title={"Yogesh Gramopadhye"} subTitle={"Indian Resident director"} />


            </div> */}

            <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
                <Row>
                    <Col xs={12} md={6}>
                        <h2>Contact Details:</h2>
                        <div>
                            <p>
                                <MdLocationOn /> HANSA-FLEX (IFP) INDIA PVT. LTD. <br />
                                Plot No PAPV95, MIDC, Chakan Industrial Area, Village Vasuli, Taluka Khed, Pune, Maharashtra – 410501, India
                            </p>
                            <p>
                                <MdEmail /> Email: <a href="mailto:inp@hansa-flex.com" style={{ color: 'white' }}>inp@hansa-flex.com</a>
                            </p>
                            <p>
                                <MdPhone /> Telephone: 02135-678304
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>


        </div>
    );
}

export default IFP;