import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgHydra_Centerspread from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgHydra_Imagetext_01 from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Imagetext_01.jpg'
import imgHydra_Imagetext_02 from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Imagetext_02.jpg'
import imgHydra_Imagetext_03 from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Imagetext_03.jpg'
import { Container, Row, Col } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgHydra_Imagecard_01 from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Imagecard_01.jpg'
import imgHydra_Imagecard_02 from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Imagecard_02.jpg'
import imgHydra_Imagecard_03 from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/Hydra_Imagecard_03.jpg'
import { MdDescription } from 'react-icons/md'
import { BsCheckLg } from 'react-icons/bs'
import HFTab from '../../../Components/HfTabs/HfTabs'
import ComprensiveService from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/ComprehensiveService.jpg'
import UpToDate from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/UpToDate.jpg'
import MaximumFlexibility from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/MaximumFlexibility.jpg'
import OptimallyStocked from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/OptimallyStocked.jpg'
import ConvenientlyAvailable from '../../../assets/Services/Logistics solutions/Hydraulic workshop containers/ConvenientlyAvailable.jpg'


const Hydraulicworkshopcontainers = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Hydra_Imagetext_01 = {
    image: imgHydra_Imagetext_01,
    title: 'Fully equipped ',
    description: ['Hydraulic Service Containers are perfectly prepared for their specific use on your premises: they are equipped with hose cutting, peeling and embossing machines, nipple insertion device, hose reels, a compressor, hose cleaning system and, on request, a high-pressure test bench. They also have enough space to store hose materials and components. In these containers, technicians assemble customised hydraulic hose lines in accordance with DIN/EN/ISO standards and mark them in accordance with DIN/EN/ISO 4403. Whether you want individualised container insulation, air-conditioning, a double container or higher performance machines: we can offer customer-specific solutions for all fields of use.'],
  }

  const Hydra_Imagetext_02 = {
    image: imgHydra_Imagetext_02,
    title: 'Training and advice included ',
    description: ['The services from HANSA‑FLEX are not limited to setting up the hydraulic containers: on request, our hydraulics experts also hold more intensive training sessions directly on site. In these sessions, employees obtain all the necessary knowledge and skills to preassemble hoses and components in accordance with the applicable standards. Successful completion of their training is confirmed with a certificate. Support from the Hydraulic Service Container service continues for the duration of the project: technicians from the nearest HANSA‑FLEX branch are available to you and your employees to answer any questions or perform any task..'],
  }


  const Hydra_Imagetext_03 = {
    image: imgHydra_Imagetext_03,
    title: 'Reliable material supply ',
    description: ['The services from HANSA‑FLEX are not limited to setting up the hydraulic containers: on request, our hydraulics experts also hold more intensive training sessions directly on site. In these sessions, employees obtain all the necessary knowledge and skills to preassemble hoses and components in accordance with the applicable standards. Successful completion of their training is confirmed with a certificate. Support from the Hydraulic Service Container service continues for the duration of the project: technicians from the nearest HANSA‑FLEX branch are available to you and your employees to answer any questions or perform any task..'],
  }

  const data = {
    listItems: ["Reduction of machine downtimes", "Maximum availability of hose lines all round the clock and anywhere in the world", "Comprehensive support from experienced hydraulics experts", "Organisation of the delivery and pick-up of the container", "Uniform system with customised fit out of machines, equipment and material", "Can also be used as a stationary resource for major projects in industrial plants"]
  }

  //HfTab data
  const imageTextData = [
    {
      title: "Comprehensive service",
      description: "Hydraulic Service Containers offer more: extra services from HANSA‑FLEX round off everything the Hydraulic Service Containers provide and can be flexibly fine-tuned to the requirements of the customer and the project. ",
      listItems: ["Design, fitting out, stocking and delivery of the container", "On-site training of maintenance personnel", "Extended technical support for the ongoing operations ", "Refilling and 24 hour emergency service "],
      image: ComprensiveService
    },
    {
      title: "Up to date",
      description: "Hydraulic Service Containers are fitted out with the latest machines and equipment. The perfect conditions for quickly and efficiently producing hose lines in accordance with EN/ISO/DIN standards.",
      listItems: ["Latest machines and equipment ", "Safe and reliable operation guaranteed ", "Acceptance inspection by certified experts after installation ", "Individualised fitting out on request "],
      image: UpToDate
    },
    {
      title: "Maximum flexibility",
      description: "Hydraulic Service Containers are available in floor areas of 15 m², 30 m², 45 m² and 60 m². Their modular construction allows them to be flexibly laid out for every proposed use",
      listItems: ["Different sizes, available as single or double containers ", "Safe and reliable operation guaranteed ", "Individualised fitting out on request ", "Machine packages can be integrated into your workshop "],
      image: MaximumFlexibility
    },
    {
      title: "Optimally stocked",
      description: "The drawer system and the intuitive descriptions avoid unnecessary searching. Brackets for storing hose reels and components complement ample set down areas and working surfaces. ",
      listItems: ["Uniform system for storing components ", "Component storage organised in drawers and racks ", "Adequate set down areas and working surfaces", "Machines available for stationary workshops"],
      image: OptimallyStocked
    },
    {
      title: "Convenient availability",
      description: "Hydraulic Service Containers can be hired or purchased for projects. A local HANSA‑FLEX branch can manage both options.",
      image: ConvenientlyAvailable,
      listItems: ["In South Africa both options are available"]
    },
  ];

  const tabTitles = [
    { title: "Comprehensive service", eventKey: "Comprehensive service" },
    { title: "Up to date", eventKey: "Up to date" },
    { title: "Maximum flexibility", eventKey: "Maximum flexibility" },
    { title: "Optimally stocked", eventKey: "Optimally stocked" },
    { title: "Convenient availability", eventKey: "Convenient availability" },
  ];

  return (
    <div>
      <CenterStage
        imageSrc={imgHydra_Centerspread}
        title={"THE MOBILE WORKSHOP: HYDRAULIC SERVICE CONTAINER"}
        paragraph={"Whether it is a stormy off-shore drilling rig, icy building site in the mountains, red-hot desert region, a major project or on-site services provided by HANSA‑FLEX’s Industrial Service: a Hydraulic Service Container is a fully fitted hydraulics workshop and can offer you maximum hydraulic hose line availability anywhere in the world, greatly reducing time and money lost due to standstills."}
      />

      <SectionHeader
        title={"CUSTOMISED HOSE LINES MADE WHEREVER YOU LIKE"}
        subtitle={"HANSA‑FLEX Hydraulic Service Containers: flexible, fully equipped, can be used anywhere ."}
      />



      <div >
        <br />
        <ImageText data={Hydra_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Hydra_Imagetext_02} />
      </div>

      <div >
        <br />
        <ImageText data={Hydra_Imagetext_03} left={false} />
      </div>



      <SectionHeader
        title={"THESE ARE YOUR ADVANTAGES"}
        subtitle={"With the Hydraulic Service Containers, you can manufacture all the required hose lines for your project whenever you like. Equipped with the latest machines and high-quality components, Hydraulic Service Containers mean you can expect maximum process reliability and minimum standstill times."}
      />
      <div className='d-flex flex-column justify-content-center mx-auto p-2' style={{ minWidth: 300, maxWidth: 900 }}>
        <div>
          <BsCheckLg className="li-promoter" /> <span>Reduction of machine downtimes.</span>
        </div>
        <div className='mt-2'>
          <BsCheckLg className="li-promoter" /> <span>Maximum availability of hose lines all round the clock and anywhere in the world.</span>
        </div>
        <div className='mt-2'>
          <BsCheckLg className="li-promoter" /> <span>Comprehensive support from experienced hydraulics experts.</span>
        </div>
        <div className='mt-2'>
          <BsCheckLg className="li-promoter" /> <span>Organisation of the delivery and pick-up of the container.</span>
        </div>
        <div className='mt-2'>
          <BsCheckLg className="li-promoter" /> <span>Uniform system with customised fit out of machines, equipment and material.</span>
        </div>
        <div className='mt-2'>
          <BsCheckLg className="li-promoter" /> <span>Can also be used as a stationary resource for major projects in industrial plants.</span>
        </div>
      </div>


      <SectionHeader
        title={"SERVICE FEATURES – AN OVERVIEW "}
        subtitle={"What our Hydraulic Service Containers can offer you ."}
      />
      <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />

      {/* <SectionHeader
        title={"UNDER HARSH CONDITIONS OR AS A STATIONARY WORKSHOP "}
        subtitle={"Hydraulic Service Containers from HANSA‑FLEX are versatile in use and are at home anywhere in the world  ."}
      /> */}

      {/* TODO: Points */}


      <SectionHeader
        title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
        subtitle={""}
      />
      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHydra_Imagecard_01} learnMoreLink="/mobile-rapid-hydraulic-service" title="24/7 availability: the mobile emergency service from HANSA‑FLEX" desc="We are there for you in an emergency, all round the clock: our Rapid Hydraulic Service can quickly replace defective components such as hose lines." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgHydra_Imagecard_01} learnMoreLink="/assembly-and-installation" title="From installation to servicing: HANSA‑FLEX Industrial Service" desc="We offer you a complete service through our HANSA‑FLEX Industrial Service. The service technicians can do everything for you: from installation to professional servicing and." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHydra_Imagecard_01} learnMoreLink="/Worksinworks" title="Maximum process reliability: works-in-works manufacturing  " desc="If you wish, our employees can manufacture the required components on site and supply them Just-In-Sequence to your production lines." />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Hydraulicworkshopcontainers