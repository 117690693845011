import React, { useEffect } from 'react';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgManagementStage from '../../assets/stage-2.jpg';
import ContactBubble from '../../Components/ContactBubble/ContactBubble';
import imgMarianne from '../../assets/management/DSC_6123.jpg';
import imgAlistair from '../../assets/management/DSC_6008.jpg';


function Management() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <CenterStage title={"Meet our Management"} imageSrc={imgManagementStage} paragraph={"At HANSA-FLEX, our management is influential in guiding the company with strategic vision and operational excellence. Through dynamic leadership and a focus on innovation, we prioritize customer satisfaction and sustainable growth. Our management fosters a culture of collaboration and empowerment, driving success across all facets of the organization."} />

            <div>
                <ContactBubble
                    imageSrc={imgAlistair}
                    title="Alistair Wiggins"
                    subTitle="Managing Director"
                    description="Alistair Wiggins, our influential Managing Director, leads with strategic vision, fostering growth and innovation. With a wealth of experience, he drives excellence across our organization, ensuring success and customer satisfaction."
                />
                {/* <ContactBubble
                    imageSrc={imgMarianne}
                    title="Marianne Schmitt"
                    subTitle="Operations Director"
                    description="Marianne Schmitt, our esteemed Operations Director, drives operational excellence with dedication and precision. With her leadership, we optimize processes, ensuring efficiency and delivering exceptional results to our valued customers."
                /> */}
            </div>
        </div>
    );
}

export default Management;