import CustomNavbar from './Components/Navbar/CustomNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import MainNavbarMenu from './Components/Navbar/MainNavbarMenu';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProductsPage from './Pages/ProductsPage';
import NewsEventsPage from './Pages/NewsEventsPage';
import HansaFlexIndiaPage from './Pages/HansaFlexIndiaPages/HansaFlexIndiaPage';
import HansaFlexGroupPage from './Pages/HansaFlexGroupPages/HansaFlexGroupPage';
import HomePage from './Pages/HomePage';
import IFP from './Pages/HansaFlexIndiaPages/IFP';
import MHP from './Pages/HansaFlexIndiaPages/MHP';
import SHP from './Pages/HansaFlexIndiaPages/SHP';
import MSO from './Pages/HansaFlexIndiaPages/MSO';
import HfFooter from './Components/HfFooter/HfFooter';
import Journey from './Pages/HansaFlexIndiaPages/Journey';
import Management from './Pages/HansaFlexIndiaPages/Management';
import GroupManagement from './Pages/HansaFlexGroupPages/GroupManagement';
import GroupHistory from './Pages/HansaFlexGroupPages/GroupHistory';
import GroupMissionStatement from './Pages/HansaFlexGroupPages/GroupMissionStatement';
import Certificates from './Pages/HansaFlexIndiaPages/Certificates';
import SixtyYearOfHansaflex from './Pages/HansaFlexGroupPages/SixtyYearOfHansaFlex';

import PrivayPolicyPage from './Pages/PrivacyPolicy';
import CookieConsent from 'react-cookie-consent';

import TrainingNews from './Pages/NewsAndEvents/trainingNews';
import Wholesale from './Pages/NewsAndEvents/Wholesale';
import ElectraMining from './Pages/NewsAndEvents/ElectraMining';
import AboutUsSA from './Pages/HansaFlexIndiaPages/AboutUsSA';


import HosesAndHoseLines from './Pages/ProductPages/HosesAndHoseLines/HosesAndHoseLines';
import HydraulicHose from './Pages/ProductPages/HosesAndHoseLines/HydraulicHose';
import MetalHoses from './Pages/ProductPages/HosesAndHoseLines/MetalHoses';
import IndustrialHoses from './Pages/ProductPages/HosesAndHoseLines/IndustrialHoses';
import PerformedHoses from './Pages/ProductPages/HosesAndHoseLines/PerformedHoses';
import ExtremelyHighPerformanceHoses from './Pages/ProductPages/HosesAndHoseLines/ExtremelyHighPerformanceHoses';
import HoseLineConfigurator from './Pages/ProductPages/HosesAndHoseLines/HoseLineConfigurator';
import PtfeHoses from './Pages/ProductPages/HosesAndHoseLines/PtfeHoses';
import Accessories from './Pages/ProductPages/HosesAndHoseLines/Accessories';
import DriveAndControlTechnology from './Pages/ProductPages/DriveAndControlTechnology/DriveAndControlTechnology';
import Pumps from './Pages/ProductPages/DriveAndControlTechnology/Pumps';
import HydraulicMotor from './Pages/ProductPages/DriveAndControlTechnology/HydraulicMotor';
import Cylinder from './Pages/ProductPages/DriveAndControlTechnology/Cylinder';
import SwitchesAndSensors from './Pages/ProductPages/DriveAndControlTechnology/SwitchesAndSensors';
import ValveTechnology from './Pages/ProductPages/DriveAndControlTechnology/ValveTechnology/ValveTechnology';
import PipelineInstallationValve from './Pages/ProductPages/DriveAndControlTechnology/ValveTechnology/PipelineInstallationValve';
import CartridgeValves from './Pages/ProductPages/DriveAndControlTechnology/ValveTechnology/CartridgeValves';
import CetopValves from './Pages/ProductPages/DriveAndControlTechnology/ValveTechnology/CetopValves';
import MeasuringTechnology from './Pages/ProductPages/DriveAndControlTechnology/MeasuringTechnology';
import SevenHundredBarHydraulics from './Pages/ProductPages/DriveAndControlTechnology/SevenHundredBarHydraulics';
import PressureAccumulators from './Pages/ProductPages/DriveAndControlTechnology/PressureAccumulators';
import SealingTechnology from './Pages/ProductPages/SealingTechnology';
import Filtration from './Pages/ProductPages/Filtration';
import Pneumatics from './Pages/ProductPages/Pneumatics';
import TechnicalInformation from './Pages/ProductPages/TechnicalInformation';
// import Approvals from './Pages/ProductPages/Approvals';
import Catalogues from './Pages/ProductPages/Catalogues';
import HydraulicConnectionTechnology from './Pages/ProductPages/HydraulicConnectionTechnology/HydraulicConnectionTechnology';
import HoseFittings from './Pages/ProductPages/HydraulicConnectionTechnology/HoseFittings';
import CompressionFittings from './Pages/ProductPages/HydraulicConnectionTechnology/CompressionFittings';
import Adapters from './Pages/ProductPages/HydraulicConnectionTechnology/Adapters';
import Couplings from './Pages/ProductPages/HydraulicConnectionTechnology/Couplings';
import BallValves from './Pages/ProductPages/HydraulicConnectionTechnology/BallValves';
import Flanges from './Pages/ProductPages/HydraulicConnectionTechnology/Flanges';
import Pipelines from './Pages/ProductPages/Pipelines/Pipelines';
import Pipebends from './Pages/ProductPages/Pipelines/Pipebends';
import ExpansionJoints from './Pages/ProductPages/Pipelines/ExpansionJoints';
import PipeendProcessing from './Pages/ProductPages/Pipelines/PipeendProcessing';


import HoseManagement from './Pages/ServicesPages/PreventativeMaintainance/HoseManagement/HoseManagement';
import FluidService from './Pages/ServicesPages/FluidManagement/FluidService';
import MobileService from './Pages/ServicesPages/MobileRapidHydraulicService';
import HydraulicAcademy from './Pages/ServicesPages/HydraulicAccademy';
import OilAnalysis from './Pages/ServicesPages/FluidManagement/OilAnalysis';
import OilFilteringAndCleaning from './Pages/ServicesPages/FluidManagement/OilFilteringAndCleaning';
import AssemblyAndInstallation from './Pages/ServicesPages/AssembellyAndInstallation/AssemblyAndInstallation';
import ModernisationAndOptimisation from './Pages/ServicesPages/AssembellyAndInstallation/ModernisationAndOptimisation';
import PlantProjectPlanningAndManagement from './Pages/ServicesPages/AssembellyAndInstallation/PlantProjectPlanningAndManagement';
import InstallationOfPipeAndHoseLineSystems from './Pages/ServicesPages/AssembellyAndInstallation/InstallationOfPipeAndHoseLineSystems';
import PreventataiveMaintainance from './Pages/ServicesPages/PreventativeMaintainance/PreventataiveMaintainance';
import ServicePackages from './Pages/ServicesPages/PreventativeMaintainance/HoseManagement/ServicePackages';
import XCodeIdentification from './Pages/ServicesPages/PreventativeMaintainance/HoseManagement/XCodeIdentification';
import XCodeManager from './Pages/ServicesPages/PreventativeMaintainance/HoseManagement/XCodeManager/XCodeManager';
import Licenses from './Pages/ServicesPages/PreventativeMaintainance/HoseManagement/XCodeManager/Licenses';
import Inspection from './Pages/ServicesPages/PreventativeMaintainance/Inspection/Inspection';
import IndustrialHoseInspection from './Pages/ServicesPages/PreventativeMaintainance/Inspection/IndustrialHoseInspection';
import DguvInspection from './Pages/ServicesPages/PreventativeMaintainance/Inspection/DguvInspection';
import LiftingPlatformsAndLoadingBridges from './Pages/ServicesPages/PreventativeMaintainance/Inspection/LiftingPlatformsAndLoadingBridges';
import RepairAndOverhaul from './Pages/ServicesPages/RepairAndOverhaul/RepairAndOverhaul';
import PressureAccumulatorsAndAssociatedSystems from './Pages/ServicesPages/RepairAndOverhaul/PressureAccumulatorsAndAssociatedSystems';
import HydraulicCylinderRepair from './Pages/ServicesPages/RepairAndOverhaul/HydraulicCylinderRepair';
import DigitalServices from './Pages/ServicesPages/Digital Services/DigitalServices';
import EDIandBMEcat from './Pages/ServicesPages/Digital Services/EDIandBMEcat';
import Scannersolutions from './Pages/ServicesPages/Digital Services/Scannersolutions';
import Production from './Pages/ServicesPages/Production/Production';
import CustomisedAssembly from './Pages/ServicesPages/Production/CustomisedAssembly';
import SeriesProduction from './Pages/ServicesPages/Production/SeriesProduction';

import Logisticssolutions from './Pages/ServicesPages/Logistics solutions/Logisticssolutions';
import Warehousemanagement from './Pages/ServicesPages/Logistics solutions/Warehousemanagement';
import Kanbandelivery from './Pages/ServicesPages/Logistics solutions/Kanbandelivery'
import Kitting from './Pages/ServicesPages/Logistics solutions/Kitting';
import Hydraulicworkshopcontainers from './Pages/ServicesPages/Logistics solutions/Hydraulicworkshopcontainers';
import Worksinworks from './Pages/ServicesPages/Logistics solutions/Worksinworks';

import Engineering from './Pages/ServicesPages/Enginnering/Engineering';
import EngineeredbyHansaflex from './Pages/ServicesPages/Enginnering/EngineeredbyHansaflex';
import Hydraulicunit from './Pages/ServicesPages/Enginnering/Hydraulicunit';
import Prototypemanufacture from './Pages/ServicesPages/Enginnering/Prototypemanufacture';
import Pressureaccumulatorinspection from './Pages/ServicesPages/Pressureaccumulatorinspection';
import ContactUs from './Pages/HansaFlexIndiaPages/ContactUs';
import Approvalsnew from './Pages/HansaFlexIndiaPages/Approvalsnew';
import Marinizedtube from './Pages/ProductPages/Hydraulic line tube/Marinizedtube'
import Stainlesssteeltube from './Pages/ProductPages/Hydraulic line tube/Stainlesssteeltube';
import Steeltube from './Pages/ProductPages/Hydraulic line tube/Steeltube';
import Zistatube from './Pages/ProductPages/Hydraulic line tube/Zistatube';
import TermsAndConditions from './Pages/HansaFlexIndiaPages/TermsAndConditions';
import AfricanExportsDivision from './Pages/ServicesPages/AfricanExportsDivision';
import HydraulicLineTube from './Pages/ProductPages/HydraulicLineTube';


function App() {


  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className='primary-navbar'>
            <CustomNavbar />
          </div>

        </header>
        <div className='secondary-navbar sticky-top '>
          <MainNavbarMenu />
        </div>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/about-sa" exact component={AboutUsSA} />


          <Route path="/products" component={ProductsPage} />
          <Route path="/news-events" component={NewsEventsPage} />
          <Route path="/hf-india" component={HansaFlexIndiaPage} />
          <Route path="/hf-group" component={HansaFlexGroupPage} />
          <Route path="/ifp" component={IFP} />
          <Route path="/mhp" component={MHP} />
          <Route path="/shp" component={SHP} />
          <Route path="/mso" component={MSO} />

          <Route path="/journey" component={Journey} />

          <Route path="/hf-group-management" component={GroupManagement} />
          <Route path="/hf-group-history" component={GroupHistory} />
          <Route path="/hf-group-mission-statement" component={GroupMissionStatement} />
          <Route path="/hf-60-years-of-hansaflex" component={SixtyYearOfHansaflex} />

          <Route path="/certificates" component={Certificates} />
          {/* <Route path="/careers" component={Careers} /> */}
          <Route path="/Hansa-Flex-india-privacy-policy" component={PrivayPolicyPage} />
          <Route path="/Hansa-Flex-terms-and-conditions" component={TermsAndConditions} />
          <Route path="/mobile-rapid-hydraulic-service" component={MobileService} />
          <Route path="/hydraulic-academy" component={HydraulicAcademy} />

          <Route path="/training-news" component={TrainingNews} />


          <Route path="/Wholesale" component={Wholesale} />
          <Route path="/electra-mining" component={ElectraMining} />
          <Route path="/management" component={Management} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/Approvalsnew" component={Approvalsnew} />

          {/* HF Products Pages */}
          <Route path="/products" component={ProductsPage} />
          <Route path="/hoses-and-hose-lines" component={HosesAndHoseLines} />
          <Route path="/hose-line-config" component={HoseLineConfigurator} />
          <Route path="/hydraulic-hoses" component={HydraulicHose} />
          <Route path="/metal-hoses" component={MetalHoses} />
          <Route path="/Industrial-hoses" component={IndustrialHoses} />
          <Route path="/performed-hoses" component={PerformedHoses} />
          <Route path="/ext-high-pfm-hoses" component={ExtremelyHighPerformanceHoses} />
          <Route path="/Ptfe-hoses" component={PtfeHoses} />
          <Route path="/hose-accessories" component={Accessories} />

          <Route path="/drive-and-control-tech" component={DriveAndControlTechnology} />
          <Route path="/pumps" component={Pumps} />
          <Route path="/hydraulic-motor" component={HydraulicMotor} />
          <Route path="/cylinder" component={Cylinder} />
          <Route path="/switches-and-sensors" component={SwitchesAndSensors} />
          <Route path="/valve-tech" component={ValveTechnology} />
          <Route path="/pipeline-installation-valve" component={PipelineInstallationValve} />
          <Route path="/cartridge-valve" component={CartridgeValves} />
          <Route path="/cetop-valve" component={CetopValves} />
          <Route path="/measuring-tech" component={MeasuringTechnology} />
          <Route path="/seven-hundred-hydraulics" component={SevenHundredBarHydraulics} />
          <Route path="/pressure-accumulators" component={PressureAccumulators} />

          <Route path="/hydraulic-connection-technology" component={HydraulicConnectionTechnology} />
          <Route path="/hose-fitting" component={HoseFittings} />
          <Route path="/Compression-fitting" component={CompressionFittings} />
          <Route path="/adapters" component={Adapters} />
          <Route path="/coupling" component={Couplings} />
          <Route path="/ball-valves" component={BallValves} />
          <Route path="/flanges" component={Flanges} />

          {/* <Route path="/sealing-tech" component={SealingTechnology} /> */}
          <Route path="/filtration" component={Filtration} />
          <Route path="/pneumatics" component={Pneumatics} />
          <Route path="/technical-information" component={TechnicalInformation} />
          {/* <Route path="/approvals" component={Approvals} /> */}
          <Route path="/catalogues" component={Catalogues} />

          <Route path="/pipelines" component={Pipelines} />
          <Route path="/pipebends" component={Pipebends} />
          <Route path="/pipeend-processing" component={PipeendProcessing} />
          <Route path="/Expansionjoints" component={ExpansionJoints} />


          <Route path="/hydraulic-line-tube" component={HydraulicLineTube} />
          {/* <Route path="/Marinizedtube" component={Marinizedtube} />
          <Route path="/Stainlesssteeltube" component={Stainlesssteeltube} />
          <Route path="/Steeltube" component={Steeltube} />
          <Route path="/Zistatube" component={Zistatube} /> */}



          {/* HF Services Pages */}
          <Route path="/hose-management" component={HoseManagement} />
          <Route path="/mobile-rapid-hydraulic-service" component={MobileService} />
          <Route path="/hydraulic-academy" component={HydraulicAcademy} />
          <Route path="/african-export-division" component={AfricanExportsDivision} />
          {/* <Route path="/fluid-service" component={FluidService} /> */}
          <Route path="/oil-analysis" component={OilAnalysis} />
          <Route path="/oil-filtering-and-cleaning" component={OilFilteringAndCleaning} />

          <Route path="/assembly-and-installation" component={AssemblyAndInstallation} />
          <Route path="/modernisation-and-optimisation" component={ModernisationAndOptimisation} />
          <Route path="/plant-project-planning-and-management" component={PlantProjectPlanningAndManagement} />
          <Route path="/installation-of-pipe-and-hoseline-systems" component={InstallationOfPipeAndHoseLineSystems} />

          <Route path="/preventative-maintainance" component={PreventataiveMaintainance} />
          <Route path="/service-packages" component={ServicePackages} />
          <Route path="/x-code-hose-identification-system" component={XCodeIdentification} />
          {/* <Route path="/x-code-manager" component={XCodeManager} /> */}
          <Route path="/licenses" component={Licenses} />
          <Route path="/inspection" component={Inspection} />
          <Route path="/industrial-hose-inspection" component={IndustrialHoseInspection} />
          <Route path="/dguv-inspection" component={DguvInspection} />
          <Route path="/lifting-platforms-and-loading-bridges" component={LiftingPlatformsAndLoadingBridges} />

          <Route path="/repair-and-overhaul" component={RepairAndOverhaul} />
          <Route path="/hydraulic-cyclinder-repair" component={HydraulicCylinderRepair} />
          <Route path="/pressure-accumulators-and-associated-systems" component={PressureAccumulatorsAndAssociatedSystems} />

          <Route path="/DigitalServices" component={DigitalServices} />
          <Route path="/EDIandBMEcat" component={EDIandBMEcat} />
          <Route path="/Scannersolutions" component={Scannersolutions} />
          <Route path="/Production" component={Production} />
          <Route path="/Customisedassembly" component={CustomisedAssembly} />
          <Route path="/SeriesProduction" component={SeriesProduction} />

          <Route path="/Logisticssolutions" component={Logisticssolutions} />
          <Route path="/Warehousemanagement" component={Warehousemanagement} />
          <Route path="/Kanbandelivery" component={Kanbandelivery} />
          <Route path="/Kitting" component={Kitting} />
          <Route path="/Hydraulicworkshopcontainers" component={Hydraulicworkshopcontainers} />
          <Route path="/Worksinworks" component={Worksinworks} />

          <Route path="/Engineering" component={Engineering} />
          <Route path="/EngineeredbyHansaflex" component={EngineeredbyHansaflex} />
          <Route path="/Hydraulicunit" component={Hydraulicunit} />
          <Route path="/Prototypemanufacture" component={Prototypemanufacture} />

          <Route path="/Pressureaccumulatorinspection" component={Pressureaccumulatorinspection} />

        </Switch>

        <div>
          <HfFooter />
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="HFCookieConsent"
          style={{ background: '#4571a2', color: '#fff', textAlign: 'center' }}
          buttonStyle={{ color: '#fff', backgroundColor: '#d40606', fontSize: '14px' }}
          expires={365}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </div>
    </Router>
  );
}

export default App;
