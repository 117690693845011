import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'

import Stage from '../../../assets/Products/HydraulicConnectionTechnology/CompressionFittings/Stage.jpg'
import SealingCone from '../../../assets/Products/HydraulicConnectionTechnology/CompressionFittings/SafelyConnect.jpg'
import Rotary from '../../../assets/Products/HydraulicConnectionTechnology/CompressionFittings/Rotary.jpg'
import SafelyConnect from '../../../assets/Products/HydraulicConnectionTechnology/CompressionFittings/SafelyConnect.jpg'
import OptimumFlow from '../../../assets/Products/HydraulicConnectionTechnology/CompressionFittings/OptimumFlow.jpg'
import LowWear from '../../../assets/Products/HydraulicConnectionTechnology/CompressionFittings/LowWear.jpg'
import EasyAssembly from '../../../assets/Products/HydraulicConnectionTechnology/CompressionFittings/EasyAssembly.jpg'
import ExcellentCompatibility from '../../../assets/Products/HydraulicConnectionTechnology/CompressionFittings/ExcellentCompatibility.jpg'
import FormPipe from '../../../assets/Products/HydraulicConnectionTechnology/CompressionFittings/FormPipe.jpg'
import BentPipe from '../../../assets/Products/HydraulicConnectionTechnology/CompressionFittings/BentPipe.jpg'
import HFTabs from '../../../Components/HfTabs/HfTabs'


const  CompressionFittings = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "Maximum performance",
            description: "Every fitting is perfectly matched to the hose and thus offers the highest protection against leaks.",
            image: imgMark
        },
        {
            title: "Comprehensive service",
            description: "All fittings are available individually. We are also delighted to supply them fitted to hose lines.",
            image: imgMark
        },
        {
            title: "Maximum safety",
            description: "Extensive tests ensure the functional capability of the fittings up to 500 bar.",
            image: imgMark
        },
        {
            title: "Excellent corrosion protection",
            description: "Thanks to a zinc-nickel coating, the press fittings are resistant to red rust for 1,200 h.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    const Data1 = {
        title: '24° sealing cone and flare fitting',
        description: 'HANSA‑FLEX pipe fittings with a 24° sealing cone and an O-ring offer double protection against leaks: in addition to their elastomeric seal, the fittings also have a metallic seal created by the tapered shape of the sealing cone. HANSA‑FLEX flare fittings are particularly useful in high-pressure applications where there are strong vibrations, high pressure peaks and loads. On the fitting side, the seal is achieved by the O-ring; on the pipe side by the metal surfaces between the flare cone and the intermediate ring.',
        image: SealingCone
    }

    const Data2 = {
        title: 'Rotary & welded fittings',
        description: 'Rotary fittings from HANSA‑FLEX join hose lines to a fixed point on systems with rotating or swivelling machine components. They prevent torsional strain on the hose and thus avoid premature wear. Welded fittings can be welded on directly at the point where they are needed. The HANSA‑FLEX welded cone fitting has an O-ring and is shaped so as to fit precisely inside the counterpart of the phosphated and oiled threaded connector.',
        image: Rotary
    }

    const Data3 = {
        listItems: [
            "Compatible with standardised threaded connections of other manufacturers, DNV GL certified and DVGW tested",
            "Large faces in contact with the wrench ensure a secure hold during tightening",
            "Direction setting allows for maximum flexibility",
            "Permissible working pressures are above the pressure ranges stated in DIN EN ISO 8434-1",
            "High red-rust corrosion resistance from the HANSA‑FLEX zinc-nickel coating",
            "Ozone-resistant O-rings avoid premature component failure"
        ],
        image: SafelyConnect
    }

    const Data4 = {
        listItems: [
            "Wide range of threaded fitting types",
            "Different sealing functions can be provided: from metallic and O-rings to soft seals",
            "Available in different materials, including stainless steel and brass",
            "Cutting ring fittings with factory pre-installed cutting rings can be supplied",
            "Fittings with thread types such as BSP, BSPT, UNF, NPT, JIS or SAE",
            "Straight pipe fittings, 45° and 90° elbows in T-, L- and cross-shaped versions",
            "Sealing functions for the screw-in sides: O-ring, soft seal, metallic and conical sealing types"
        ]
    };


    //HfTab data
    const imageTextData = [
        {
            description: "HANSA‑FLEX pipe fittings are designed to achieve optimum flow rates. Turbulence, the associated pressure and performance losses and cavitation due to pressure peaks are avoided. This increases the service life of every component in the fluid system.",
            listItems: ["Extremely good flow rates", "Optimum direction of volumetric flow", "Avoidance of cavitation"],
            image: OptimumFlow
        },
        {
            description: "HANSA‑FLEX sets new standards in corrosion protection: the surfaces of the body, union nuts and cutting rings of the pipe fitting have a CRVI-free zinc-nickel coating as standard. This guarantees high corrosion resistance in accordance with DIN 50979 of up to 1,500 hours.",
            listItems: [
                "Manufactured from cold-drawn or forged material",
                "Zinc-nickel surface coating",
                "Other materials such as stainless steel or brass on request"
            ],
            image: LowWear
        },
        {
            description: "All HANSA‑FLEX fittings have large, flat contact faces to give wrenches a secure hold. During installation, the fitting is held safely and securely in the wrench. The highest installation safety is ensured by over-tightening protection and a clearly detectable increase in force.",
            listItems: [
                "Nut attachment ensures the connection is safe against pull out",
                "Reliably sealed with metallic and elastomeric seals on reinstallation",
                "Checked during installation using a patented cutting ring gauge"
            ],
            image: EasyAssembly
        },
        {
            description: "HANSA‑FLEX pipe fittings are available with external threads complying with various standards so that they can be combined with all commercially available connecting threads. Interchangeability with metric fittings is ensured because the pipe-side connection of HANSA‑FLEX cutting ring fittings complies with DIN 3861 type of port end W and DIN EN ISO 8434-1.",
            listItems: [
                "Metric and inch external and internal threads in accordance with the standards",
                "Cylindrical US threaded connections in accordance with ISO 11926-2/3",
                "NPT threads in accordance with ANSI/ASME B1.20.1, SAE J 476"
            ],
            image: ExcellentCompatibility
        }
    ];

    const tabTitles = [
        { title: "Optimum flow", eventKey: "Optimum flow" },
        { title: "Low wear", eventKey: "Low wear" },
        { title: "Easy assembly", eventKey: "Easy assembly" },
        { title: "Excellent compatibility", eventKey: "Excellent compatibility" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"SECURELY CONNECT HYDRAULIC COMPONENTS WITH PIPE FITTINGS"}
                    paragraph={"Transitions between components in hydraulic systems are always a point for careful consideration. Pipe fittings from HANSA‑FLEX are standardised in accordance with DIN EN ISO 8434-1 and DIN 2353. Their tight positive fit ensures a reliable connection even under high working pressures, pressure peaks and vibrations. They are proof against liquid and gas leaks, have flow-optimised transitions and are available in various types, sizes and materials. "}
                />
            </div>

            <div>
                <SectionHeader
                    title={"PIPE FITTINGS FOR EVERY PURPOSE"}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"SAFELY CONNECT PIPES"}
                    subtitle={"HANSA‑FLEX pipe fittings come into their own where high pressures and safety are essential."}
                />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"THE PERFECT FILTER SOLUTION FOR ALL HYDRAULICS APPLICATIONS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"THE HANSA‑FLEX PIPE FITTINGS RANGE"}
                />
                <ImageText
                    data={Data4} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={FormPipe} learnMoreLink={"/pipelines"}
                                title="HF-Form pipe forming system"
                                desc={"HF-Form is a pipe forming system developed by HANSA‑FLEX which incorporates redundant seals to connect pipes without cutting rings, special parts or special tools."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={BentPipe} learnMoreLink={"/pipebends"}
                                title="Bent pipes"
                                desc={"The HANSA‑FLEX pipe bending centre can produce bent pipes either individually or in batches from a wide range of different materials. Its services also include"} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default  CompressionFittings