import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgWorks_Centerspread from '../../../assets/Services/Logistics solutions/Works in works/Works_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgWorks_Imagetext_01 from '../../../assets/Services/Logistics solutions/Works in works/Works_Imagetext_01.jpg'
import imgWorks_Imagetext_02 from '../../../assets/Services/Logistics solutions/Works in works/Works_Imagetext_02.jpg'
import imgWorks_Imagetext_03 from '../../../assets/Services/Logistics solutions/Works in works/Works_Imagetext_03.jpg'
import imgWorks_Imagetext_04 from '../../../assets/Services/Logistics solutions/Works in works/Works_Imagetext_04.jpg'
import imgWorks_Imagetext_05 from '../../../assets/Services/Logistics solutions/Works in works/Works_Imagetext_05.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { Container, Row, Col } from 'react-bootstrap';
import imgWorks_Imagecard_01 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagecard_01.jpg'
import imgWorks_Imagecard_02 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagecard_02.jpg'
import imgWorks_Imagecard_03 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagecard_03.jpg'


const Worksinworks = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Works_Imagetext_01 = {
    image: imgWorks_Imagetext_01,
    title: 'Comprehensive service',
    description: ['Comprehensive on-site range of products: the high material availability assured by our works-in-works service allows the required components to be manufactured quickly and on the spot. In addition, you save on warehousing costs because materials are used quickly after delivery directly on site in response to production needs. All the required materials are processed directly on site. You could, for example, count on having the required components available at the very start of a shift.'],
  }

  const Works_Imagetext_02 = {
    image: imgWorks_Imagetext_02,
    title: 'Competent contact partners',
    description: ['Benefit from our on-site support and rely on our experienced employees. From a simple hose line to complicated power units right up to prototype manufacture, you can rely on us and our comprehensive competence for the manufacture of hydraulic components. We make sure that our processes are fully synchronised and integrated into your production processes.'],
  }


  const Works_Imagetext_03 = {
    image: imgWorks_Imagetext_03,
    title: 'Training your employees',
    description: ['As part of our works-in-works service, we can provide training for your employees. The training details what should be considered important when fitting or installing our products. Your employees learn step-by-step how to manufacture and how to install them correctly. In this way you can prevent failures and avoid complaints from end customers.'],
  }

  const Works_Imagetext_04 = {
    image: imgWorks_Imagetext_04,
    title: 'Optimised logistics',
    description: ['In addition to the manufacture of hydraulic hose lines, our employees also deal with the warehousing and the in-house logistics, so that you can concentrate fully and completely on assembly or installation. We order the required materials to suit the current demand – if necessary the required hose lines can be on site within 24 hours. '],
  }

  const Works_Imagetext_05 = {
    image: imgWorks_Imagetext_05,
    title: 'Comprehensive advice',
    description: ['We place particular emphasis on working with you on the basis of complete trust and confidence. Because we are fully focused on your on-site production, we can find the best solution in every situation. We accept every challenge and never lose sight of the overall picture, even with complex process sequences. If you would like to optimise your machines and equipment, we are available to actively support you and present you with efficient improvement proposals.'],
  }

  const advantagesData = {
    listItems: ["Immediate availability of spare parts", "Competent contact partner directly on site", "Individualised and efficient solutions", "Warehousing costs reduced", "Any required unforeseen adaptations are quickly made on the spot", "We bear the administration costs for warehousing"]
  }

  return (
    <div>
      <CenterStage
        imageSrc={imgWorks_Centerspread}
        title={"WORKS-IN-WORKS: MANUFACTURING DIRECTLY ON SITE"}
        paragraph={"Immediately available materials and components – that is the big advantage of the works-in-works manufacturing service from HANSA‑FLEX. The integrated manufacturing workshops are made available by our employees on site. They provide the required components Just-In-Sequence for your production lines. Due to our many years of experience, we bring a proven record of successful solution finding in the manufacture of hydraulic hoses and components."}
      />



      <SectionHeader
        title={"WORKS-IN-WORKS SERVICE – AN OVERVIEW"}
        subtitle={"The complete service for your production floor."}
      />


      <div >
        <br />
        <ImageText data={Works_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Works_Imagetext_02} />
      </div>

      <div >
        <br />
        <ImageText data={Works_Imagetext_03} left={false} />
      </div>



      <SectionHeader
        title={"THESE ARE YOUR ADVANTAGES"}
        subtitle={"The works-in-works service makes your daily tasks considerably easier."}
      />
      <ImageText data={advantagesData} left={false} />

      {/* TODO: points */}

      <SectionHeader
        title={"INTEGRATED SERVICES"}
        subtitle={"The works-in-works service simply offers more."}
      />

      <div >
        <br />
        <ImageText data={Works_Imagetext_04} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Works_Imagetext_05} />
      </div>


      <SectionHeader
        title={"YOU MAY ALSO BE INTERESTED IN THIS"}
        subtitle={""}
      />

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgWorks_Imagecard_01} learnMoreLink="/Hydraulicworkshopcontainers" title="Hydraulic Workshop Container" desc="For any company wanting flexibility when working beyond the factory gates, the Hydraulic Service Container is the optimum solution." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgWorks_Imagecard_02} learnMoreLink="/Engineering" title="Engineering and project planning management" desc="Power units, cylinders special solutions: The specialist HANSA‑FLEX teams manufacture components for hydraulic engineering, power unit and plant engineering systems tailored" />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgWorks_Imagecard_03} learnMoreLink="/hydraulic-academy" title="Training courses" desc="We can offer your employees a comprehensive training programme with live training sessions, either online or face-to-face." />
          </Col>

        </Row>
      </Container>

    </div>
  )
}



export default Worksinworks