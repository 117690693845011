import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import ImageText from '../../Components/ImageText/ImageText'
import imgSteelTube from '../../assets/Products/HydraulicLineTube/Stainless steel tube.png'
import imgMildTube from '../../assets/Products/HydraulicLineTube/mild steel_02.jpg'
import imgCenterStage from '../../assets/Products/HydraulicLineTube/Produkte_Rohrendenbearbeitung_06_2.jpg'
import imgAutomotiveIndu from '../../assets/Products/HydraulicLineTube/Automative industry.jpg'
import imgMarineIndu from '../../assets/Products/HydraulicLineTube/marine industry.jpg'

const HydraulicLineTube = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        title: "Mild Steel Hydraulic Tube (EN10204/2.2 - DIN 2391/C ST37.4)",
        description: "The HANSA-FLEX mild steel hydraulic tube range is precision seamless pipes manufactured to DIN 2391/C ST37.4 standards. These tubes are ideal for hydraulic applications, offering excellent working pressure. Mild steel hydraulic tubes are widely used across several industries due to their strength, durability, and cost-effectiveness. Their versatility and reliability make them a popular choice in various sectors and industries. Available in sizes ranging from 4mm to 50mm.",
        image: imgMildTube
    }

    const data2 = {
        title: "Stainless Steel Hydraulic Tube (ASTM A269)",
        description: "The HANSA-FLEX stainless-steel hydraulic tubes meet ASTM A269 standards. These seamless tubes offer excellent corrosion resistance and are commonly found in the construction industry for heavy machinery and infrastructure projects, and in automotive manufacturing for braking and suspension systems. The oil and gas sector relies on these tubes for high-pressure applications, while the pharmaceutical and food and beverage industries use them for their sanitary properties and resistance to chemicals.",
        image: imgSteelTube
    }

    const data3 = {
        title: "Zista Tubing (DIN 74234-B6 - DIN 2394/C-94)",
        description: "The HANSA-FLEX Zista tube range adheres to DIN 74234-B6 and DIN 2394/C-94 specifications. This tube is widely used in the automotive industry for brake lines however their versatility make them a popular choice across various sectors. HANSA-FLEX has excellent stock availability and is considered as the largest stockiest in Southern Africa. Available in Metric and imperial sizes.",
        image: imgAutomotiveIndu
    }

    const data4 = {
        title: "Marinized Tube",
        description: "Marinized hydraulic steel tubes are specifically designed for use in marine environments. They are treated to resist corrosion from saltwater and other harsh conditions typically found at sea. These tubes are commonly used in shipbuilding for hydraulic systems that control steering, stabilizers, and other critical functions. HANSA-FLEX is one of a few hydraulic suppliers in Southern Africa who offers a cost-effective alternative to stainless steel tube specifically designed for Marine Applications.",
        image: imgMarineIndu
    }

    return (
        <div>
            <CenterStage
                imageSrc={imgCenterStage}
                title={"LEADING STEEL TUBE SUPPLIER IN SOUTH AFRICA"}
                paragraph={"Hydraulic steel tube transfer fluid safely through the system and is a excellent choice for heat dissipation and static applications. The HANSA‑FLEX range includes stainless steel, mild steel. Zista and marinized tube. "}
            />

            <ImageText data={data1} />
            <ImageText data={data2} left={false} />
            <ImageText data={data3} />
            <ImageText data={data4} left={false} />
        </div>
    )
}

export default HydraulicLineTube