import React, { useEffect } from 'react';
import FullPageCarousel from '../Components/Carousel/FullPageCarousel';
import ImageCard from '../Components/ImageCard/ImageCard';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './PageCss/HomePage.css';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import CenterStage from '../Components/CenterStage/CenterStage';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageDescription from '../Components/ImageDescription/ImageDescription';
import imgIndustries from '../assets/Whoweare/SouthAfrica-Industries Served 1.png'



import imgSA_Product1 from "../assets/Products/perfectHoseLine.jpg"
import imgSA_Product2 from "../assets/Products/SA_Product2.jpg"
import imgSA_Product3 from "../assets/Products/SA_Product3.jpg"
import imgSA_Product4 from "../assets/Products/SA_Product4.jpg"
import imgSA_Product5 from "../assets/Products/SA_Product5.jpg"

import imgSA_Service1 from "../assets/Services/RapidHydraulicService.jpg"
import imgSA_Service2 from "../assets/Services/SA_Service2.jpg"
// import imgSA_Service3 from "../assets/Services/SA_Service3.jpg"
import Professional from '../assets/Services/AssemblyAndInstallation/InstallationOfPipesAndHoseLineSystems/Professional.jpg'
import imgSA_Service4 from "../assets/Services/SA_Service4.jpg"
import imgSA_Service5 from "../assets/Services/SA_Service5.jpg"


import imgSAnews1a from "../assets/news-events/SAnews1a.jpg"
import imgSAnews2a from "../assets/news-events/SAnews2a.jpg"
import imgElectraMining from "../assets/news-events/electra_mining2024.jpg"
import imgElectraMining_banner from "../assets/news-events/electraMining_banner.jpg"

import imgHose from '../assets/hose.jpg';
import imgMetalHose from '../assets/metal-hose.jpg';
import imgIndustrialHose from '../assets/industrial-hose.jpg';
import imgHighPressureHose from '../assets/high-pressure-hose.jpg'
import qualitySticker from '../assets/QualityLogo.png';
import glob from '../assets/GlobLogo.png';
import customersatisfaction from "../assets/customer satisfaction.png";
import BBEE from "../../../HF-South-Africa/src/assets/BBEE.png"
import service from "../../../HF-South-Africa/src/assets/service.png";
import techexpert from "../../../HF-South-Africa/src/assets/techexpert.png"
import exporsalesteam from "../../../HF-South-Africa/src/assets/export sales team.png"

import hose from '../assets/HoseLogo.png';
import isoLogo from '../assets/ISO-Logo.png';
import imgCarton from '../assets/Hansa-Flex_Kartons_Shop.png';
// import imgDigitilization from '../assets/DigilisationImg.jpg'
// import imgLogistics from '../assets/LogisticsImg.jpg';
// import imgTrainingCourse from '../assets/TrainingCourseImg.jpg';
// import imgIree from '../assets/news-events/iree.jpg';
// import imgGarry from '../assets/news-events/garry.jpg';
// import imgGCC from '../assets/news-events/gcc.jpg';
// import imgTree from '../assets/news-events/tree.jpg';
// import imgBauma from '../assets/news-events/bauma.jpg';
import imgMachine from '../assets/machine.jpg';
import imgHighPressurePipe from '../assets/high-pressure-pipe.jpg';
// import ContactBubble from '../Components/ContactBubble/ContactBubble';
// import imgIFP from '../assets/IFP1.jpg';
// import imgMHP from '../assets/MHP1.jpg';
// import imgSHP from '../assets/SHP1.jpg';


import Promoter from '../Components/Promoter/Promoter';
import FeatureCardCarousel from '../Components/FeaturesCardCarousal/FeatureCardCarousal'

// import stage1 from '../assets/stage-1.jpg';
// import HFTab from '../Components/HfTabs/HfTabs';
// import FeaturesCard from '../Components/FeaturesCard/FeaturesCard';
// import ShowcaseCarousel from '../Components/ShowcaseCarousel/ShowcaseCarousel';
// import HfFooter from '../Components/HfFooter/HfFooter'
// import ImageText from '../Components/ImageText/ImageText';
// import imgGoetheVisit from '../assets/news-events/goethe.jpg';

import AOS from 'aos';
import 'aos/dist/aos.css';

import HoseManagement from '../assets/Services/HoseManagement.jpg'
import FluidServices from '../assets/Services/fluidService.jpg'
import MobileService from '../assets/Services/MobileService.jpg'
import HydraulicAcademy from '../assets/Services/HydraulicAcademy.jpg'
import CredentialsImg from '../assets/Services/Credentials.jpg'

import circleCheck from '../assets/check-circle.svg'
import imgtraining from '../assets/news-events/trainingEvent.jpg'
import imgOem from '../assets/news-events/oemCup3.jpg'
import imgHeritage from '../assets/news-events/heritageDay.jpg'
import imgElectra from '../assets/news-events/electraNews2.jpg'
import imgMark from '../assets/mark.png'
import imgGlobegold from '../assets/Globegold.png'
import imgHFwholesaleseta from '../assets/news-events/HFwholesaleseta.jpg'




function HomePage() {

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 320, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 300, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, []);

  // const carouselItems = [
  //   {
  //     imgSrc: imgIFP,
  //     title: 'IFP - Insert Ferrule and Fittings Unit',
  //     description: 'Our state-of-the-art manufacturing facility is dedicated to crafting premium Insert & Ferrules products.',
  //     link: '/ifp'
  //   },
  //   {
  //     imgSrc: imgMHP,
  //     title: 'MHP - Metal Hose Production Unit',
  //     description: 'The journey of Hansa-Flex India commenced with this unit initially focusing on serial hose production. Over time, our operations expanded',
  //     link: '/mhp'
  //   },
  //   {
  //     imgSrc: imgSHP,
  //     title: 'SHP - Serial Hose Production Unit',
  //     description: "Welcome to Hansa-Flex India's Serial Hose Production. Our journey began humbly in 2018 with a small unit, which you can explore further.",
  //     link: '/shp'
  //   }
  // ];


  const featuresData2 = [
    {
      title: "Global Expertise",
      description: "We have over 30 years’ experience in the African market and 60 years of global expertise.",
      image: imgGlobegold
    },
    {
      title: "Customer Satisfaction ",
      description: "We are highly customer focused and service orientated.",
      image: customersatisfaction
    },

    // {
    //   title: "Always at your side",
    //   description: "We offer a 24/7 hydraulic service with fully equipped mobile vans, 365 days a year",
    //   image: service
    // },

    {
      title: "Technical Expertise",
      description: "We have highly trained, professional, and experienced staff.",
      image: techexpert
    },
    {
      title: "BBBEE Certified",
      description: "We are committed to transformation and are currently a Level 3 BBBEE contributor with 30%+1 black ownership. ",
      image: BBEE
    },
    // {
    //   title: "Extensive branch & distribution network",
    //   description: "We have an international footprint with a dense branch & distribution network in South Africa and the African continent. ",
    //   image: glob
    // },
    {
      title: "African Footprint",
      description: "We have a Dedicated exports sales team providing quality products to the whole African continent. ",
      image: exporsalesteam
    },
    {
      title: "Quality First Approach.",
      description: "All products are subject to comprehensive testing within the HANSA-FLEX quality assurance system. ",
      image: qualitySticker
    },
    // {
    //   title: "ISO standards approved..",
    //   description: "We are committed to the highest standards. We are DIN EN ISO 9001:2015 certified!",
    //   image: isoLogo
    // },
  ]
  const featuresData1 = [
    {
      title: "Highest Quality Standards",
      description: "Pipeline components that meet the customer’s requirements, manufactured precisely to the highest quality standards and in accordance with international standards.",
      image: imgMark
    },
    {
      title: "System Solutions from a Single Source",
      description: "One contact partner for everything and all components are designed to work together perfectly.",
      image: imgMark
    },
    {
      title: "Decade of Experience",
      description: " Advice and manufacture by experienced experts with know-how extending across many industries.",
      image: imgMark
    },

    // Add more objects for additional FeaturesCard data
  ];

  //promoter content

  const promoterTitle = 'PLACE YOUR ORDER TODAY!';
  const promoterChecklistItems = [
    'The full HANSA‑FLEX product range with more than 80,000 articles',
    'Category-specific filter parameters enable quick navigation',
    'Current availability shown in real-time for better planning certainty'
  ];
  const promoterButtonLabels = ['CONTACT US', 'FIND OUT MORE !'];

  //image text data for heighlight points
  const highlightImgTxtData = {
    image: imgMachine,
    title: 'Leading system partner for hydraulics',
    description: 'HANSA‑FLEX offers a 360° fluid technology service for the planning, operation and optimisation of mobile and stationary hydraulic systems. An extensive product range naturally complements our comprehensive service portfolio – from highly efficient and flexible repair and installation, to complex engineering and consulting services – with our close-knit branch network, specialised manufacturing plants, digital service offerings and innovative logistics solutions tying it all together.',

    buttonName: "Learn more"
  }

  const highlightImgTxtData2 = {
    image: imgHighPressurePipe,
    title: 'The premium hose line for high pressures',
    description: "The HD 700 LL premium hose line from HANSA-FLEX represents a pinnacle of engineering excellence, meticulously crafted to excel even in the most demanding of conditions. This hose line is the result of extensive research, development, and testing, aimed at delivering unparalleled performance and durability.",

    buttonName: "Learn more"
  }
  //

  const googleReviewsLink = "https://www.google.com/search?q=hansa+flex+south+africa&rlz=1C1GCEU_enIN1077IN1078&oq=hansa+flex+south+africa&gs_lcrp=EgZjaHJvbWUyDggAEEUYJxg5GIAEGIoFMgYIARAjGCcyCAgCEAAYFhgeMg0IAxAAGIYDGIAEGIoFMg0IBBAAGIYDGIAEGIoFMg0IBRAAGIYDGIAEGIoFMg0IBhAAGIYDGIAEGIoFMgoIBxAAGIAEGKIEMgoICBAAGIAEGKIEMgoICRAAGIAEGKIE0gEJNjUwMmowajE1qAIIsAIB&sourceid=chrome&ie=UTF-8#lrd=0x1e951483a4bbd8d1:0xbc15b2a18496004a,1,,,,";

  return (
    <div>
      <FullPageCarousel />

      <SectionHeader
        title="Explore our Products"
      />
      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgSA_Product1} learnMoreLink="/hydraulic-hoses" title="The perfect hose line for every application " desc="HANSA-FLEX preassembles high-quality hydraulic hose lines for low, medium and high pressures, covering all applications in mobile and stationary hydraulics. The HANSA-FLEX product portfolio also includes industrial and preformed hoses for the transport of fluids, chemicals, food, air, water and abrasive media." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgSA_Product2} learnMoreLink="/metal-hoses" title="Metal hoses for extreme conditions " desc="The materials used for metal hoses make them extremely resistant to corrosion and abrasion. Their excellent temperature tolerance, strength under vacuum and outstandingly low permeability make metal hoses the preferred choice for transporting solid, liquid and gaseous substances under particularly demanding conditions. HANSA-FLEX offers comprehensive advice on the selection of the best metal hose solution." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgSA_Product3} learnMoreLink="/ext-high-pfm-hoses" title="Hydraulic steel tube for high-pressure applications" desc="HANSA-FLEX South Africa is the largest stockist and supplier of hydraulic line tube in steel and stainless steel in Southern Africa." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgSA_Product4} learnMoreLink="/hydraulic-connection-technology" title="Hydraulic threaded connection technology in HANSA-FLEX quality" desc="HANSA-FLEX offers an extensive range of robust and durable hydraulic connection elements such as adapters, fittings, and connectors." /></Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgSA_Product5} learnMoreLink="/seven-hundred-hydraulics" title="Hydraulic components up to 700 bar " desc="HANSA-FLEX offers a wide range of hydraulic components for every application." />
          </Col>
        </Row>
      </Container>
      <br />
      <br />
      <div>
        <SectionHeader title={"Leading Mild Steel, Stainless Steel and Zista Tube Supplier in South Africa"} subtitle={"HANSA-FLEX offers customers all they need to equip their machines and systems with safe, high-quality of pipelines.  "} />
        <FeatureCardCarousel featuresData={featuresData1} />
      </div>
      <SectionHeader
        title="Our Services"
      />
      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgSA_Service1} learnMoreLink="/mobile-rapid-hydraulic-service" title="Rapid hydraulic response, ready for action at any time " desc="In unplanned downtimes, every minute counts. Optimally equipped with a mobile workshop on board and coordinated by an experienced service technician, the service fleet at HANSA-FLEX provides rapid assistance in an emergency." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgSA_Service2} learnMoreLink="/Hydraulicworkshopcontainers" title="The mobile workshop: Hydraulic service container" desc='Customised hose lines made even in the most remote locations. HANSA‑FLEX Hydraulic Service Containers:  flexible, fully equipped, can be used anywhere.' />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={Professional} learnMoreLink="/installation-of-pipe-and-hoseline-systems" title="Pipe and hose installation for complete plants" desc="The pipes and hoses of hydraulic machines and systems must be replaced regularly for safety reasons alone. But other projects such as increasing efficiency can also be the reason for a complete replacement. With excellently trained service technicians, we complete such orders reliably, to a high standard and also within tight time frames." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgSA_Service4} learnMoreLink="/SeriesProduction" title="Series production of hose lines" desc="Our branches deliver tens of thousands of articles including ready-to-install high-pressure, PTFE and industrial hose lines, made-to-measure and in small or large batches, to OEMs every month. With us, you can expect excellent process reliability and compliance with quality standards. " />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgSA_Service5} learnMoreLink="/Worksinworks" title="Works-in-works: manufacturing directly on-site" desc="Immediately available materials and components – that is the big advantage of the works-in-works manufacturing service from HANSA-FLEX. The integrated manufacturing workshops are made available by our employees on site. They provide the required components Just-In-Sequence for your production lines. Due to our many years of experience, we bring a proven record of successful solution finding in the manufacture of hydraulic hoses and components." />
          </Col>
        </Row>
      </Container>

      {/* <div>
        <SectionHeader
          title="Explore our units!"
          paragraph="We have our units in Pune, which provide extensive range of products!"
        />
        <ShowcaseCarousel carouselItems={carouselItems} descDisplay={isMobile ? 'none' : 'block'} />
      </div>
      <br /><br /><br /> */}

      <div className='div-gray center-all-items'>
        <SectionHeader
          title="Advantages of working with HANSA-FLEX South Africa"
          subtitle=" Benefit from a strong local presence backed by a global network, providing innovative, high-quality hydraulic solutions and building lasting customer relationships."
        />
        <FeatureCardCarousel featuresData={featuresData2} />
      </div>

      {/* <div>
        {isLaptop && (
          <Promoter
            title={promoterTitle}
            checklistItems={promoterChecklistItems}
            btnLabel1={"Find Out More !"}
            btnLabel1Link={"/hoses-and-hose-lines"}
            imageUrl={imgCarton}
          />
          // TODO: contact us button
        )}
      </div> */}

      <br /><br /><br />
      <div className="text-center div-gray">
        <SectionHeader
          title={"Check out our reviews on google!"}
          paragraph={"Curious about what others have to say about us? Take a moment to explore our Google reviews and discover why our customers rave about their experiences with us. "} />
        <br />
        <Button variant="primary" href={googleReviewsLink} target="_blank" className="btn-google" style={{ boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }}>View our reviews on Google</Button>

        <br /><br /><br />
      </div>

      <div>
        <SectionHeader
          title="News and Events" />
        <Container className="custom-container">
          <Row>
            <Col sm={12} md={6} lg={6} data-aos="fade-up"><ImageCard image={imgHFwholesaleseta} learnMoreLink={'/Wholesale'} title="HANSA-FLEX South Africa Empowering Youth" desc="We are deeply honoured and grateful for the recognition by the Wholesale and Retail SETA for our contributions to people development, particularly in supporting unemployed youth through skills development initiatives." /></Col>
            <Col sm={12} md={6} lg={6} data-aos="fade-up"><ImageCard image={imgElectraMining_banner} learnMoreLink={'/electra-mining'} title="Electra mining 2024" desc="Join us at Electra Mining 2-6 September 2024 to experience our latest advancements in hydraulic technology. Don't miss this opportunity to elevate your operations with our cutting-edge solutions!" /></Col>
            {/* <Col sm={12} md={6} lg={6} data-aos="fade-up" className='d-flex justify-content-center align-items-center' style={{ fontSize: '20px', color: '#dbdbdb' }} >More to come...</Col> */}

            {/* <Col sm={12} md={6} lg={6} data-aos="fade-up" className='d-flex justify-content-center align-items-center' style={{ fontSize: '20px', color: '#dbdbdb' }} >More to come...</Col> */}

          </Row>
        </Container>
      </div>

      <br /><br /><br />
      <div >
        <SectionHeader title={"Industries Served"} />
        <div data-aos={isMobile ? "fade-up" : "fade-up"} className="home-imageContainer">
          {isMobile ? <>
            <img src={imgIndustries} alt="Description of the image" className='home-img' />
          </> : <>
            <img src={imgIndustries} alt="Description of the image" className='home-img' />
          </>}
        </div>
        <br /><br /><br /><br />
      </div>
    </div>
  );
}

export default HomePage;
