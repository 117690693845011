import React, { useEffect } from 'react';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgSHP from '../../assets/units/SHP/shp.jpg';
import NumberGrid from '../../Components/NumberGrid/NumberGrid';
import imgIndustrialHose from '../../assets/units/SHP/IndustrialHose.jpg';
import imgHydraulicHose from '../../assets/units/SHP/Hydraulichoses.jpg';
import imgAirconditionHose from '../../assets/units/SHP/AirconditionHose.jpg';
import imgHoseProtection from '../../assets/units/SHP/hoseprotection.jpg';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../Components/ImageCard/ImageCard';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";

function SHP() {
    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 200, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 300, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        });
    }, []);

    //hall of fame
    const numbers = ['105+', '200,000+', '200+', '200%'];
    const subtitles = ['Employees', 'production of hoses per month', 'Active customers', 'Growth percent'];

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <CenterStage
                imageSrc={imgSHP}
                title={"Serial Hose Production "}
                paragraph={"Welcome to HANSA-FLEX India's Serial Hose Production. Our journey began humbly in 2018 with a small unit, which you can explore further(MHP). As we continued to grow and to better support our customers with higher volumes, we shifted to a new location in 2023. The move was a strategic step to enhance our efficiency and capacity in delivering quality serial hose products."}
            />

            <div data-aos="zoom-out">
                <SectionHeader title={"Explore our Product Line!"} />
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={8}><ImageCard image={imgIndustrialHose} title="Industrial Hoses" desc={"Industrial hoses are flexible, reinforced tubes designed for various applications within industrial settings. These hoses play a crucial role in conveying liquids, gases, and powders between different components of machinery, equipment, and systems. Industrial hoses are constructed from a variety of materials, including rubber, PVC, polyurethane, and metal, depending on the intended use and the specific requirements of the industry.."} /></Col>
                        <Col sm={12} md={6} lg={4}><ImageCard image={imgHydraulicHose} title="Hydraulic Hoses" desc="Hydraulic hoses are specialized, high-pressure tubes designed to transmit hydraulic fluid within hydraulic systems. These systems are commonly found in machinery and equipment used in industries such as construction, agriculture, manufacturing, and automotive. Hydraulic hoses play a critical role in transmitting power and control signals between different components of hydraulic machinery." /></Col>
                        <Col sm={12} md={6} lg={4}><ImageCard image={imgAirconditionHose} title="Air Condition Hoses" desc="Air conditioning hoses are specialized tubes designed for the transfer of refrigerants and compressed air within automotive air conditioning (AC) systems. These hoses play a crucial role in maintaining the proper functioning of AC systems by facilitating the circulation of refrigerant, which is essential for cooling the air inside a vehicle." /></Col>
                        <Col sm={12} md={6} lg={8}><ImageCard image={imgHoseProtection} title="Hose Protection" desc="Hose protection sleeves are specialized coverings designed to safeguard hoses from various environmental factors, abrasion, and potential damage, ensuring prolonged service life and optimal performance." /></Col>
                    </Row>
                </Container>
            </div>

            <div data-aos="fade-up" className='div-gray'>
                <SectionHeader title={"SHP in Numbers"} />
                <NumberGrid numbers={numbers} subtitles={subtitles} />
            </div>

            <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
                <Row>
                    <Col xs={12} md={6}>
                        <h2>Contact Details:</h2>
                        <div>
                            <p>
                                <MdLocationOn /> HANSA-FLEX INDIA PVT LTD - UNIT II <br />
                                PLOT NO. E3/5, PHASE 3, MIDC, KURULI, near HP PETROL PUMP, Chakan, Pune, Maharashtra 410501
                            </p>
                            <p>
                                <MdEmail /> Email: <a href="mailto:inp@hansa-flex.com" style={{ color: 'white' }}>inp@hansa-flex.com</a>
                            </p>
                            <p>
                                <MdPhone /> Telephone: 02135-352920 / 908
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default SHP;
